import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  imageContainer: {
    height: '350px',
  },
  titleContact: {
    width: '50%',
    color: 'white', 
    fontSize: '3rem',
    position: 'relative',
    bottom: '100px',
    left: '30px',
    fontWeight: 'bolder',
  },
  containerForm: {
    width: '50%',
    '& .MuiTextField-root': { m: 2, width: '60ch' },
  },
  inputForm: {
    color: 'red',
    height: '50px',
  },
  containerBottom: {
    display: 'flex',
    justifyContent: 'left',
    paddingTop: '5px',
  },
  Bottom: {
    background: '',
    border: 0,
    borderRadius: '30px 30px 30px 30px',
    color: 'white',
    height: 48,
  },
});

export default useStyles;
