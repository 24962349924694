import { makeStyles } from '@mui/styles';

export default makeStyles({
  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '85vh',
    backgroundColor: '#fafafa',
    border: '0.5px solid #dfe0eb',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
  },
  filterDiv: {
    height: '100%',
    padding: '16px 24px',
    overflowY: 'auto',
  },
  title: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#31374c',
  },
  subTitle: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#777d92',
    marginBottom: '10px',
  },
  hrFilter: {
    margin: '24px 0',
  },
  searchMajor: {
    background: '#ffffff',
    height: '40px',
    border: '0.5px solid #dfe0eb',
    boxSizing: 'border-box',
    borderRadius: '12px',
    padding: '13px 16px',
    fontSize: '14px',
  },
  buttonApplyFilters: {
    width: '90%',
    background: '#007bff',
    borderRadius: '12px !important',
    padding: '12px !important 16px !important 12px !important 16px !important',
  },
  buttonApplyFiltersWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '16px',
    paddingBottom: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  probabilityOfQuittingReferences: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '2%',
  },
});
