import axios from 'utils/axios';
import { getToken } from 'services/user/user.service';
import { IInfo } from './info.interface';

/**
 * Service for user information and filters
 */
const InfoService = {
  /**
   * @param token user's token
   * @returns graph data ready to render
   */
  getFilters: async (
    filters: any = {},
    id = '',
    currentUrl = '',
  ): Promise<IInfo> => {
    const token = getToken();

    try {
      const result: any = await axios.post(
        `${currentUrl}?access_token=${token}&id=${id}`,
        filters,
      );

      return {
        filters: result.data.filtros,
        rol: result.data.rol,
      };
    } catch (e) {
      return {
        filters: [
          {
            nombre: '',
            min: 0,
            max: 0,
            enabled: false,
            tipo: '',
            valor: 0,
            valores: [{ nombre: '', codigo: '', enabled: false, valor: false }],
            mode: '',
            codigo: '',
          },
        ],
        rol: {
          asignaturas: [],
          carreras: [],
          coordinador: false,
          director: false,
          docente: false,
          itrs: [],
          rol: '',
          rol_id: 0,
          rol_type: '',
        },
      };
    }
  },
};

export default InfoService;
