import React from 'react';

import { Grid } from '@mui/material';

import ContactForm from 'components/contactForm/Form/ContactForm';

const Contacto = () => {
  return (
    <Grid container>
      <ContactForm />
    </Grid>
  );
};

export default Contacto;
