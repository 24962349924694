import React from 'react';
import { connect } from 'react-redux';

import { Grid, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import List from 'components/list/List';
import { IHeaders } from 'components/list/ListHeaders/ListHeaders.interface';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import SearchInput from 'components/rootComponents/Standards/SearchInput/SearchInput';
import useLists from 'utils/customHooks/useLists';
import { IDocentes } from '../Docentes.interface';
import { generateAndDownloadCsvContentList } from 'utils/helper';

/**
 *
 * Docentes view to display different teachers list
 *
 * @component
 */
const DocentesListado = (props: IDocentes) => {
  //Hooks
  const title = 'Docentes';
  const searchPlaceholder = 'Buscar docente...';
  const {
    users,
    columns,
    isLoadingList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    search,
    setSearch,
    sortDirection,
    setSortDirection,
    totalPages,
  } = useLists('/v1.1/docentes/listado', props.filters);

  const headerCells: IHeaders[] = [];

  users[0]?.valores.forEach((valor: any, index: number) => {
    const isNumeric =
      valor.column === 'fecha_nacimiento' || valor.column === 'generacion';

    const formattedHeader: IHeaders = {
      id: valor.column,
      label: columns[index],
      numeric: isNumeric,
    };

    headerCells.push(formattedHeader);
  });

  const teachersList = users.map((teacher: any) => {
    let formattedTeacher = {
      name: teacher.nombre,
      link: teacher.__link,
    };

    for (let i = 0; i < teacher.valores.length; i++) {
      let column = teacher.valores[i].column;
      let value = teacher.valores[i].valor;

      formattedTeacher = {
        ...formattedTeacher,
        [column]: value,
      };
    }

    return formattedTeacher;
  });

  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/docentes/graficas'}
        listUrl={'/v1.1/navegacion/docentes/listado'}
      />

      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <IconButton
          onClick={() =>
            generateAndDownloadCsvContentList(headerCells, teachersList, title)
          }
        >
          <DownloadIcon sx={{ color: '#007BFF' }} />
        </IconButton>
      </Grid>

      <Grid item xs={10} style={{ textAlign: 'right' }}>
        <SearchInput
          searchPlaceholder={searchPlaceholder}
          setSearchBar={setSearch}
        />
      </Grid>

      <Grid item xs={12}>
        <List
          search={search}
          headers={headerCells}
          rows={teachersList}
          isLoading={isLoadingList}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPages={totalPages}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(DocentesListado);
