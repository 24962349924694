import React from 'react';

import { InputBase } from '@mui/material';

import { ISearchInput } from './SearchInput.interface';
import useStyles from './SearchInput.styles';

const SearchInput = ({ searchPlaceholder, setSearchBar }: ISearchInput) => {
  const classes = useStyles();

  return (
    <InputBase
      placeholder={searchPlaceholder}
      inputProps={{
        'aria-label': 'Buscar',
      }}
      className={classes.search}
      onChange={(event) => setSearchBar(event.target.value)}
    />
  );
};

export default SearchInput;
