import { makeStyles } from '@mui/styles';

export default makeStyles({
  containerHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px',
    margin: '10px',
    borderRadius: '12px',
  },
  containerHeaderSubtitule: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatarContainer: {
    margin: '2%',
  },
  whiteTitleHeader: {
    color: 'white',
    width: '100%',
  },
  blueTitleHeader: {
    color: '#0191cd',
    width: '100%',
  },
  button: {
    background: 'white',

    '&:hover': {
      background: 'white',
    }
  },
});
