import React from 'react';

import { Box } from '@mui/system';
import { Paper, Typography } from '@mui/material';

import useStyles from './ActivityAtEduBox.style';
import ActivityAtEduModal from 'components/rootComponents/Modal/ActivityAtEduModal/ActivityAtEduModal';
import { IActivityAtEduBox } from './ActivityAtEduBox.interface';

/**
 * this component is rendered in the [[ActivityAtEdu]] section and is used to
 * display in an [[ActivityAtEduModal]] modal the student's courses.
 *
 * @component
 */
const ActivityAtEduBox = ({
  title,
  icon,
  data,
  quantity,
}: IActivityAtEduBox) => {
  const style = useStyles();
  return (
    <Paper className={style.paperContainer}>
      <Box className={style.titlePaper}>
        <Typography className={style.titlePaper}>{title}</Typography>
      </Box>
      <Box className={style.containerIconAndData}>
        <ActivityAtEduModal
          title={title}
          description={`Estos datos pertenecen a tus ${title}:`}
          icon={icon}
        >
          <ul>
            {data.map((cursos: any) => {
              return (
                <li
                  key={cursos.curso}
                  className={style.courseItem}
                >
                  {` ${cursos.curso}`}
                </li>
              );
            })}
          </ul>
        </ActivityAtEduModal>
        <Box>
          <Typography className={style.dataTitle}>{quantity}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default ActivityAtEduBox;
