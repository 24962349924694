/**
 * Reducer to retrieve user's information
 *
 * @packageDocumentation
 */
export default (state: any = {}, action: any) => {
  switch (action.type) {
    case 'SAVE_USERLOGIN': {
      return action.payload;
    }
    case 'DELETE_USERLOGIN': {
      return action.payload;
    }
    case 'RELOAD_USERLOGIN': {
      return action.payload;
    }
    case 'UPDATEFILTERS_USERLOGIN': {
      return { ...state, visibility: action.payload };
    }
    default:
      return state;
  }
};
