import React from 'react';

import { TablePagination } from '@mui/material';
import { IListPagination } from './ListPagination.interface';

/**
 * Pagination for the [[List]] component.
 *
 * @component
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ListPagination = ({
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  pagesTotal,
}: IListPagination) => {
  const disabled = pagesTotal ? rowsPerPage > pagesTotal : false;
  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={-1}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      labelRowsPerPage="Datos por página"
      labelDisplayedRows={({ from, to }) => `${from}-${to} de ${pagesTotal}`}
    />
  );
};

export default ListPagination;
