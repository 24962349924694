import { makeStyles } from '@mui/styles';

export default makeStyles({
  title: {
    margin: '0',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#31374C',
  },
});
