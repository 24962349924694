import React from 'react';

import { Container, Grid } from '@mui/material';
import { Box } from '@mui/system';

import logoUtecCTD from 'assets/img/utec_ctd.png';
import useStyles from './Footer.style';

/**
 * @component Footer
 */
const Footer = () => {
  const style = useStyles();

  return (
    <Container maxWidth={false} style={{ backgroundColor: '#333' }}>
      <Container maxWidth="xl" className={style.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <ul className={style.containerDl}>
              <dt className={style.title}>INSTITUCIONAL</dt>
              <li className={style.urlItem}>
                <a
                  href="http://utec.edu.uy"
                  target="lank"
                  className={style.item}
                >
                  Acerca de UTEC
                </a>
              </li>
              <li className={style.urlItem}>
                <a
                  href="http://taa.utec.edu.uy"
                  target="blank"
                  className={style.item}
                >
                  TAA
                </a>
              </li>
              <li className={style.urlItem}>
                <a href="/contacto" className={style.item}>
                  Contacto
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <ul className={style.containerDl}>
              <dt className={style.title}>FORMACIÓN</dt>
              <li className={style.urlItem}>
                <a
                  href="https://utec.edu.uy/formacion/"
                  target="blank"
                  className={style.item}
                >
                  Carreras
                </a>
              </li>
              <li className={style.urlItem}>
                <a
                  href="https://utec.edu.uy/formacion/#programas-especiales"
                  target="blank"
                  className={style.item}
                >
                  Programas Especiales
                </a>
              </li>
              <li className={style.urlItem}>
                <a
                  href="https://edu.utec.edu.uy"
                  target="blank"
                  className={style.item}
                >
                  EDU
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <ul className={style.containerDl}>
              <dt className={style.title}>ESTUDIANTES</dt>
              <li className={style.urlItem}>
                <a
                  href="https://portaluxxi.utec.edu.uy/"
                  target="blank"
                  className={style.item}
                >
                  Portal Académico
                </a>
              </li>
              <li className={style.urlItem}>
                <a
                  href="https://ev1.utec.edu.uy/moodle/login/index.php"
                  target="blank"
                  className={style.item}
                >
                  Entorno Virtual de Aprendizaje
                </a>
              </li>
              <li className={style.urlItem}>
                <a
                  href="https://utec.edu.uy/estudiantes/"
                  target="blank"
                  className={style.item}
                >
                  Novedades
                </a>
              </li>
              <li className={style.urlItem}>
                <a
                  href="https://utec.edu.uy/estudiantes/"
                  target="blank"
                  className={style.item}
                >
                  Descargas
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ margin: '5%' }}>
              <a href="http://utec.edu.uy" target="blank">
                <img alt="UTEC" src={logoUtecCTD} width="180" />
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              color: 'grey',
              fontSize: '80%',
              textDecoration: 'none',
            }}
          >
            <p>
              <small>
                Todos los derechos reservados 2018 -{' '}
                <a href="https://www.utec.edu.uy" style={{ color: 'grey' }}>
                  www.utec.edu.uy
                </a>
              </small>
            </p>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Footer;
