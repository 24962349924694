import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';

import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import Chart from 'components/charts/Charts';
import useCharts from 'utils/customHooks/useCharts';
import { IEncuestas } from '../Encuestas.interface';

const getSessionId = () => {
  return localStorage.getItem('sessionGroup') || '';
};
/**
 *
 * Asignaturas view to display different [[Chart]] and [[List]]
 *
 * @component
 */
const EncuestasGraficas = (props: IEncuestas) => {
  // Hooks
  const title = 'Encuestas Docentes ';
  const search = props.location.search;
  const id = new URLSearchParams(search).get('id') || getSessionId();
  const { isLoadingCharts, charts } = useCharts(
    window.location.pathname,
    props.filters,
    id
  );


  
  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/encuestas/graficas'}
      />

      {isLoadingCharts && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      )}

      {
        !isLoadingCharts && charts.map((chart, index) => (     
          chart.data && chart.data.labels && chart.chartType == 'Table' && chart.data.labels.length > 0 ? (
            <Grid item key={index} xs={12}>
              <Chart {...chart} />
            </Grid>
            
          ) : null
        ))
      }

      


      
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};


export default connect(mapStateToProps)(EncuestasGraficas);
