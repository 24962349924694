/**
 * Reducer for dashboard preferences
 * 
 * UPDATE_DASHBOARDPREFERENCES: Updates the user's preferences for home screen graphs
 * 
 * GET_DASHBOARDPREFERENCES: Retrieves user's preferences from localStorage. In a future this info will come from the API.
 * 
 * @packageDocumentation
 */
export default (state: any = {}, action: any) => {
  switch (action.type) {
    case 'UPDATE_DASHBOARDPREFERENCES': {
      return action.payload;
    }
    case 'GET_DASHBOARDPREFERENCES': {
      return action.payload;
    }
    default:
      return state;
  }
};
