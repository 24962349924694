import React, { useEffect } from 'react';

import { Box, Grid, Paper } from '@mui/material';
import Article, { getObjectPropertyValue } from './Article';
import ListPagination from 'components/list/ListPagination/ListPagination';
import Spinner from 'components/rootComponents/Standards/Spinner/Spinner';
import { IArticleList } from './ArticleList.interface';
import { standarizeString } from 'utils/helper';

/**
 * List of [[Article]] elements
 *
 * @component
 */
const ArticleList = ({
  isLoading,
  search,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  articles,
}: IArticleList) => {
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filtered =
    search?.length > 0
      ? articles.filter((article) => {
          const standarizedSearch = standarizeString(search);

          return (
            standarizeString(article.nombre).includes(standarizedSearch) ||
            standarizeString(
              getObjectPropertyValue('Asignatura', article.valores),
            ).includes(standarizedSearch)
          );
        })
      : articles;

  useEffect(() => {
    setPage(0);
  }, filtered);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyArticles =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filtered.length) : 0;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {isLoading ? (
          <Spinner />
        ) : articles.length > 0 ? (
          filtered
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((article, index) => {
              return (
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                  sm={12}
                  key={`${article.__link}-${index}`}
                >
                  <Article {...article} />
                </Grid>
              );
            })
        ) : (
          <Grid item lg={12} container justifyContent="center">
            <div style={{ width: '100%', textAlign: 'center' }}>
              No hay datos
            </div>
          </Grid>
        )}
        {emptyArticles > 0 && (
          // add whitespace to dont break the navigation
          // 275 -> article height
          // 18 -> article's margin
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            sm={12}
            style={{ height: (275 + 18) * Math.ceil(emptyArticles / 3) }}
          />
        )}
      </Grid>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <ListPagination
          rowsPerPageOptions={[6, 12, 24, 48, 100]}
          count={filtered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          pagesTotal={filtered.length}
        />
      </Paper>
    </Box>
  );
};

export default ArticleList;
