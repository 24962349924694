import { useEffect, useRef, useState } from 'react';

import { IFilter } from 'services/info/info.interface';
import { IItem } from 'services/list/list.interface';
import ListService from 'services/list/list.service';

/**
 * This hook will show a [[List]] component with users retrieved from the API
 * @param url Url to retrieve the list of [[Chart]] per view
 * @param filters Filters stored in Redux used as http request body
 * @returns List of charts to be rendered on each view
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useArticles(
  url: string,
  filters: IFilter[],
  pageSize = 200,
) {
  const emptyArticle: IItem[] = [];
  const [articles, setArticles] = useState(emptyArticle);
  const firstUpdate = useRef(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function fetchArticles(page: number, abortController: any) {
    try {
      setIsLoading(true);
      return await ListService.getItems(
        url,
        page,
        pageSize,
        filters,
        abortController,
      );
    } catch (error) {
      console.error('Error fetching articles:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let pageToRetrieve = 0;
    setArticles(emptyArticle);

    const getArticlesIteratively = async () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }

      if (filters?.length === undefined || !filters) {
        return;
      }

      let hasData = true;
      do {
        const result = await fetchArticles(
          ++pageToRetrieve,
          abortController.signal,
        );

        if (result.items?.length > 0) {
          setArticles((arts) => [...arts, ...result.items]);
        } else {
          hasData = false;
        }
      } while (hasData);
    };

    getArticlesIteratively();

    return () => {
      abortController.abort();
    };
  }, [filters]);

  return { articles, isLoading };
}
