import React from 'react';

import { Grid, Box, Button, Typography, Modal } from '@mui/material';

import { IModal } from './Modal.interface';

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

  '@media (max-width: 400px)': {
    width: '300px',
  },
};

/**
 * Base component to render modals
 * @component
 */
const BasicModalNoButton = (props: IModal) => {
  const handleClose = () => {
    props.closeFunction();
  };

  return (
    <Modal
      open={props.isOpen ?? false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ position: 'absolute' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.title}
        </Typography>
        <div>{props.description}</div>
        <Grid container justifyContent="flex-end">
          <Button variant="contained" onClick={handleClose}>
            Cerrar
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default BasicModalNoButton;
