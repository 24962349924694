import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';

import Chart from 'components/charts/Charts';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import { IActividadDigital } from './ActividadDigital.interface';
import useCharts from 'utils/customHooks/useCharts';

/**
 * /**
 * ActividadDigital view to display different [[Chart]]
 *
 * @component
 */
const ActividadDigital = (props: IActividadDigital) => {
  const title = 'Actividad Digital';
  const { isLoadingCharts, charts } = useCharts(
    window.location.pathname,
    props.filters,
  );

  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/actividad_digital/graficas'}
      />

      {isLoadingCharts && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      )}

      {!isLoadingCharts &&
        charts.map((chart, index) => {
          return (
            <Grid item key={index} xs={12}>
              <Chart {...chart} />
            </Grid>
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(ActividadDigital);
