import axios from 'utils/axios';

import { ChartType } from 'components/charts/Charts.interface';
import { IStudentProfile } from 'services';
import { getToken } from 'services/user/user.service';
import { IFilter } from 'services/info/info.interface';

/**
 * Service for [[ProfileInfo]]
 */
const UserProfileService = {
  getStudentData: async (id: string, plan: string, filters: IFilter[]) => {
    const token = getToken();
    const result: any = await axios.post(
      `/v1.1/estudiantes/estudiante?id=${id}&plan=${plan}&access_token=${token}`,
      filters,
    );

    const dataTransformer: IStudentProfile = {
      headerProfile: {
        nombre: result.data?.__data.Name || 'Sin datos',
        telefono: result.data?.__data.telefono || 'Sin datos',
        email: result.data?.__data.email || 'Sin datos',
        direccion: result.data?.__data.municipio || 'Sin datos',
        edad: result.data?.__data.edad || 'Sin datos',
        carrera: result.data?.__data.carrera || 'Sin datos',
        generacion: result.data?.__data.generacion || 'Sin datos',
        semestre: result.data?.__data.semestre || 'Sin datos',
        plan: result.data?.__data.plan || 'Sin datos',
      },
      activityMoodle: [
        {
          data: {
            datasets: result.data?.__info_moodle?.__data,
            labels: result.data?.__info_moodle?.__labels,
          },
          seeMoreUrl: '',
          chartType:
            result.data?.__info_moodle?.__meta?.type === 'curves'
              ? ChartType.Line
              : ChartType.Bar,
          isLoading: false,
          name: result.data?.__info_moodle?.__meta?.title,
          description: result.data?.__info_moodle?.__meta?.description,
        },
        {
          data: {
            datasets: result.data?.__info_moodle_foros?.__data,
            labels: result.data?.__info_moodle_foros?.__labels,
          },
          seeMoreUrl: '',
          chartType:
            result.data?.__info_moodle_foros?.__meta?.type === 'curves'
              ? ChartType.Line
              : ChartType.Bar,
          isLoading: false,
          name: result.data?.__info_moodle_foros?.__meta?.title,
          description: result.data?.__info_moodle_foros?.__meta?.description,
        },
      ],
      activityByCareer: {
        activity: {
          carrera: result.data?.__data.carrera || 'Sin datos',
          materias_aprobadas:
            result.data.__info_carreras?.result[0].materias_aprobadas ||
            'Sin datos',
          creditos_obtenidos:
            result.data.__info_carreras?.result[0].creditos_obtenidos ||
            'Sin datos',
          probabilidad_abandono:
            result.data.__info_carreras?.result[0].probabilidad_abandono ||
            'Sin datos',
          materias_matriculadas:
            result.data.__info_carreras?.result[0].materias_matriculadas ||
            'Sin datos',
          status_carrera:
            result.data.__info_carreras?.result[0].status_carrera ||
            'Sin datos',
        },
        materiaData: {
          aproved: Array.isArray(
            result.data?.__info_carreras?.result[0]?.detalle_materias,
          )
            ? result.data?.__info_carreras?.result[0]?.detalle_materias?.map(
                (materia: any) => {
                  return {
                    aproved: true,
                    label: materia.uc,
                    year: materia.ejercicio,
                  };
                },
              )
            : [],
          disapproved: Array.isArray(
            result?.data?.__info_carreras?.result[0]
              ?.detalle_materias_no_aprobadas,
          )
            ? result?.data?.__info_carreras?.result[0]?.detalle_materias_no_aprobadas?.map(
                (disapproved: any) => {
                  return {
                    aproved: false,
                    label: disapproved.uc,
                    year: disapproved.ejercicio,
                  };
                },
              )
            : [],
        },
      },
      studentProfile: [],
      activityByEdu: {
        cursos_inscriptos: {
          quantity:
            result.data?.__info_carreras?.result[0].data_ultimos_cursos_edu
              .length || 'Sin datos',
          data: '',
        },

        cursos_finalizados: {
          quantity:
            result.data?.__info_carreras?.result[0].cursos_finalizados ||
            'Sin datos',
          data: '',
        },

        ultimos_cursos: {
          quantity:
            result.data?.__info_carreras?.result[0].data_ultimos_cursos_edu
              .length || 'Sin datos',
          data: result.data?.__info_carreras?.result[0].data_ultimos_cursos_edu,
        },
      },
    };

    return dataTransformer;
  },

  getPossiblePlans: async (id: string, plan: string) => {
    const token = getToken();
    const result: any = await axios.post(
      `/v1.1/estudiantes/planes?id=${id}&plan=${plan}&access_token=${token}`,
    );
    return result.data;
  },

  getScholarshipData: async (id: string, plan: string) => {
    const token = getToken();
    const result: any = await axios.post(
      `/v1.1/estudiantes/escolaridad?id=${id}&plan=${plan}&access_token=${token}`,
    );
    return result.data.__data;
  },

  getTeacherData: async (id: string, filters: IFilter[]) => {
    const token = getToken();

    const result: any = await axios.post(
      `v1.1/docentes/docente?id=${id}&access_token=${token}`,
      filters,
    );

    const dataTransformer: any = {
      headerProfile: {
        nombre:
          `${result.data?.__data.Nombre} ${result.data?.__data.Apellido}` ||
          'Sin datos',
        carrera: result.data?.__data.Carrera || 'Sin datos',
        semestre: result.data?.__data.ITR || 'Sin datos',
        edad: result.data?.__data['Fecha de Nacimiento'] || 'Sin datos',
      },
    };

    return dataTransformer;
  },
  getStudentDataDetails: async (username: string) => {
    const token = getToken();

    const result: any = await axios.post(
      `/v1.1/estudiantes/estudiante/detalles?username=${username}&access_token=${token}`,
    );
    const dataTransformer: any = {
      headerProfile: {
        nombre:
          `${result.data[0]?.nombre} ${result.data[0].apellido}` || 'Sin datos',
        carrera: result.data[0]?.carrera || 'Sin datos',
        semestre: result.data[0]?.itr || 'Sin datos',
        edad: result.data[0]?.fecha_nacimiento || 'Sin datos',
        plan_id: result.data[0].plan_id,
        matriculacion_id: result.data[0].matriculacion_id,
      },
    };
    return dataTransformer;
  },
  getApplicantData: async (id: string, filters: IFilter[]) => {
    const token = getToken();

    const result: any = await axios.post(
      `v1.1/postulaciones/postulacion?id=${id}&access_token=${token}`,
      filters,
    );

    const dataTransformer: any = {
      headerProfile: {
        nombre:
          `${result.data?.__data.Nombre} ${result.data?.__data.Apellido}` ||
          'Sin datos',
        carrera: result.data?.__data.Carrera || 'Sin datos',
        semestre: result.data?.__data.ITR || 'Sin datos',
        edad: result.data?.__data['Fecha de Nacimiento'] || 'Sin datos',
        itr: result.data?.__data.ITR || 'Sin datos',
        direccion: result.data?.__data['Pais de Nacimiento'] || 'Sin datos',
      },
    };

    return dataTransformer;
  },
};

export default UserProfileService;
