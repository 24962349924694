import React from 'react';

import { Box } from '@mui/system';
import { Paper, Typography } from '@mui/material';
import useStyles from './PaperBoxWithIcon.style';
import { IPaperBoxWithIcon } from './PaperBoxWithIcon.interface';

/**
 * Component to set PaperBoxWithIcon on the different views
 *
 * Displays a Paper box with an icon inside
 *
 * @component
 */
const PaperBoxWithIcon = ({ title, data, icon }: IPaperBoxWithIcon) => {
  const style = useStyles();
  return (
    <Paper className={style.paperContainer}>
      <Box className={style.titlePaper}>
        <Typography className={style.titlePaper}>{title}</Typography>
      </Box>
      <Box className={style.containerIconAndData}>
        <Box className={style.iconContainer}>{icon}</Box>
        <Box>
          <Typography className={style.dataTitle}>{data}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default PaperBoxWithIcon;
