import React from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import analiticasImage from 'assets/img/img_home_1.png';
import comoFuncionaImage from 'assets/img/img_home_2.png';
import availableInformationImage from 'assets/img/img_home_3.png';
import aprendizajeAumentadoImage from 'assets/img/proyecto_img-removebg.png';

import useStyles from './LogoutHome.styles';

/**
 * Home view for not logged in users
 *
 * @component
 */
const LogoutHome = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container style={{ backgroundColor: '#0191cd' }}>
        <Grid item xs={6} style={{ margin: 'auto' }}>
          <Box sx={{ padding: '10%' }}>
            <Box>
              <Typography
                className={classes.whiteTitleHeader}
                style={{ fontFamily: 'utec_title' }}
                variant={'h4'}
              >
                APRENDIZAJE AUMENTADO
              </Typography>
              <Typography>
                La plataforma de analíticas del aprendizaje de UTEC que permite
                realizar un segumiento del ciclo de vida de los estudiantes,
                conocer su perfil y contexto.
              </Typography>
            </Box>
            <Box mt={2}>
              <Button
                href="#conoce-mas"
                size="small"
                variant="outlined"
                className={classes.button}
              >
                CONOCÉ MÁS
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <img alt="Aprendizaje aumentado" src={aprendizajeAumentadoImage} />
        </Grid>
      </Grid>

      <Grid container style={{ backgroundColor: 'white', padding: '5%' }} id="conoce-mas">
        <Grid item xs={6} style={{ margin: 'auto' }}>
          <Box sx={{ padding: '10%' }}>
            <Typography className={classes.blueTitleHeader} variant={'h4'}>
              ¿Qué información puedo ver?
            </Typography>
            <Typography>
              En AA podrás tener una mirada integral del proceso de enseñanza y
              aprendizaje e información relacionada al contexto.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <div>
            <img
              style={{ width: '100%' }}
              alt="Informacion disponible"
              src={availableInformationImage}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container style={{ backgroundColor: '#f2f2f2' }}>
        <Grid item xs={6}>
          <div>
            <img
              style={{ width: '100%' }}
              alt="Como funciona"
              src={comoFuncionaImage}
            />
          </div>
        </Grid>

        <Grid item xs={6} style={{ margin: 'auto' }}>
          <Box sx={{ padding: '10%' }}>
            <Typography className={classes.blueTitleHeader} variant={'h4'}>
              ¿Cómo funciona?
            </Typography>
            <Typography>
              AA extrae datos de las soluciones digitales de UTEC, luego los
              transforma, analiza, procesa, modela y entrena a través de
              distintas técnicas. La interfaz de visualización, en la que
              docentes, coordinadores, directores analizan indicadores de forma
              simple e intuitiva.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container style={{ backgroundColor: 'white' }}>
        <Grid item xs={6} style={{ margin: 'auto' }}>
          <Box sx={{ padding: '10%' }}>
            <Typography className={classes.blueTitleHeader} variant={'h4'}>
              ¿Analíticas del aprendizaje?
            </Typography>
            <Typography>
              Existen muchas definiciones de análiticas del aprendizaje, con
              la que nos sentimos más identificados en UTEC es: “Medición,
              recopilación, análisis y reporte de datos sobre los estudiantes y
              sus contextos, con el propósito de comprender y optimizar el
              aprendizaje y los entornos en los que se produce”. Society for
              Learning Analytics Research (SOLAR).
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <div>
            <img
              style={{ width: '100%' }}
              alt="Analiticas del aprendizaje"
              src={analiticasImage}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default LogoutHome;
