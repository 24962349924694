import React from 'react';
import { Document } from '@react-pdf/renderer';

import CoverPage from './CoverPage';
import ListPage from './listPage/ListPage';
import { IStudentScholarshipData } from './StudentScholarship.interface';

/**
 * this component renders all the student's data in scholarship pdf
 * @component
 */

// const StudentScholarship = ({data}) => {
const StudentScholarship = ({ data }: IStudentScholarshipData) => {
  return (
    <Document>
      <CoverPage data={data} />
      <ListPage data={data} />
    </Document>
  );
};

export default StudentScholarship;
