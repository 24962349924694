import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';

import LogInForm from 'components/logIn/LogInForm/LogInForm';
import Headers from 'components/logIn/Headers/Headers';
import UserService from 'services/user/user.service';
import { removeUserFromSession } from 'store/actions/userInfo/userAction';

/**
 * LogIn view
 *
 * @component
 */
const LogIn = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // by default user should be logged out if on /login
    UserService.sendLogout();
    dispatch(removeUserFromSession());
  }, []);

  return (
    <>
      <Box
        sx={{
          maxWidth: 500,
          boxShadow: '0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%)',
          borderRadius: '12px',
          textAlign: 'center',
          backgroundColor: 'white',
        }}
        m="auto"
        my={10}
        p="20px"
      >
        <Headers
          title="Iniciar sesión"
          subtitle="Ingresá con tu cuenta de UTEC"
        />

        <LogInForm />

      </Box>
    </>
  );
};

export default LogIn;
