import { combineReducers } from 'redux';

import userReducer from './reducers/userInfo/userReducer';
import navBarReducer from './reducers/navBarInfo/navBarReducer';
import filtersReducer from './reducers/filters/filtersReducer';
import dashboardReducer from './reducers/dashboard/dashboardReducer';

const rootReducer = combineReducers({
  userInfo: userReducer,
  navBarInfo: navBarReducer,
  filters: filtersReducer,
  dashboardPreferences: dashboardReducer,
});

export default rootReducer;
