import React, { useState } from 'react';

import {
  Avatar,
  CircularProgress,
  Divider,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { Box } from '@mui/system';
import DownloadIcon from '@mui/icons-material/Download';

import useStyles from './HeaderProfile.style';
import IconTag from 'components/rootComponents/Standards/iconTag/IconTag';
import { IHeaderProfileData } from './HeaderProfile.interface';
import UserService from 'services/user/user.service';
import { useHistory, useLocation } from 'react-router-dom';

const TagBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  '& .MuiChip-root': {
    marginBottom: '5px',
  },
}));

/**
 * this component renders all the student's personal data
 * @component
 */

type FetchStudentsProfileType = (
  selectedId: string,
  selectedPlan: string,
) => Promise<void>;

interface HeaderProfileProps {
  data: IHeaderProfileData;
  planes?: string[];
  fetchStudentsProfile?: FetchStudentsProfileType;
}

const HeaderProfile: React.FC<any> = ({
  data,
  planes,
  fetchStudentsProfile,
}) => {
  const location = useLocation();
  const search = location.search;
  const initialId = new URLSearchParams(search).get('id') || '0';
  const initialPlan = new URLSearchParams(search).get('plan') || '0';

  const style = useStyles();
  const history = useHistory();
  const [plan, setPlan] = useState(initialPlan);
  const [id, setId] = useState(initialId);
  const [loading, setLoading] = useState(false);

  const handlePlanChange = (event: SelectChangeEvent<string>) => {
    const [selectedPlan, selectedId] = event.target.value.split('|');
    setPlan(selectedPlan);
    setId(selectedId);
    history.push(
      `/v1.1/navegacion/estudiantes/estudiante?id=${selectedId}&plan=${selectedPlan}`,
    );
    setLoading(true);
    fetchStudentsProfile(selectedId, selectedPlan)
      .then(() => setLoading(false))
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };
  return (
    <>
      <Paper sx={{ display: 'flex' }} className={style.containerHeader}>
        <Box className={style.containerHeaderChildren}>
          <Box className={style.avatarContainer}>
            <Avatar alt="Remy Sharp" sx={{ width: 100, height: 100 }} />
          </Box>
          <Box sx={{ overflow: 'scroll' }}>
            <Typography className={style.titleHeader} variant={'h6'}>
              {data?.nombre}
            </Typography>
            <div
              style={{
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ display: 'flex' }}>
                <IconTag
                  icon={<BookmarkIcon className={style.iconColor} />}
                  label={data?.edad ? data.edad : 'Sin datos'}
                  bg="#007BFF0D"
                  color="#007BFF"
                />
                <IconTag
                  icon={<FmdGoodIcon className={style.iconColor} />}
                  label={data?.direccion ? data.direccion : 'Sin datos'}
                  bg="#007BFF0D"
                  color="#007BFF"
                />
                <IconTag
                  icon={<LocalPhoneIcon className={style.iconColor} />}
                  label={data?.telefono ? data.telefono : 'Sin datos'}
                  bg="#007BFF0D"
                  color="#007BFF"
                />
                <IconTag
                  icon={<EmailIcon className={style.iconColor} />}
                  label={data?.email ? data.email : 'Sin datos'}
                  bg="#007BFF0D"
                  color="#007BFF"
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: '1rem',
                  padding: '1rem 0',
                  alignItems: 'center',
                }}
              >
                <h3>Plan: </h3>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={`${plan}|${id}`}
                  onChange={handlePlanChange}
                >
                  {planes?.map((planOption: any) => (
                    <MenuItem
                      key={planOption.plan_id}
                      value={`${planOption.plan_id}|${planOption.matriculacion_id}`}
                    >
                      {planOption.plan_id}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            {loading ? (
              <LinearProgress />
            ) : (
              <TagBox className={style.containerHeaderSubtitule}>
                <Typography
                  variant={'subtitle2'}
                  className={style.headerChildren}
                >
                  {data?.carrera}
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography
                  variant={'subtitle2'}
                  className={style.headerChildren}
                >
                  {data?.generacion} {data?.semestre}
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography
                  variant={'subtitle2'}
                  className={style.headerChildren}
                >
                  {data?.plan}
                </Typography>
              </TagBox>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default HeaderProfile;
