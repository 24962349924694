import { IFilter } from 'services/info/info.interface';

/**
 *
 * @param filters array of filters
 * @returns dispatches an action of type `UPDATE_FILTERS` to update user's filters
 */
export const updateFilters =
  (filters: IFilter[] = []) =>
    (dispatch: any) => {
      dispatch({ type: 'UPDATE_FILTERS', payload: filters });
    };

/**
 * @returns dispatches an action of type `GET_FILTERS` to retrieve user's filters
 */
export const getFilters = () => (dispatch: any) => {
  dispatch({ type: 'GET_FILTERS' });
};


