import React, { ReactElement } from 'react';
import { IconButton, Tooltip } from '@mui/material';

interface SpinnerProps {
  data: string;
  children: ReactElement;
}

const ToolTip: React.FC<SpinnerProps> = ({ data, children }) => {
  return (
    <>
      <Tooltip title={data}>
        <IconButton sx={{ fontSize: '13px', padding: '0' }} size="small">
          {children}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ToolTip;
