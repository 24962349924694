import _ from 'lodash';
import UserService from 'services/user/user.service';

/**
 * retrieves user navbar links
 * @param token users token
 */
export const fetchUserInfo = (token: string) => (dispatch: any) => {
  _fetchuserInfo(token, dispatch);
};

const _fetchuserInfo = _.memoize(async (token, dispatch) => {
  const response = await UserService.getNavbarMenu(token);
  dispatch({ type: 'FETCH_USERINFO', payload: response });
});
