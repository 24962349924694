import React from 'react';

import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

import useStyles from './ContactData.style';

/**
 * Component used to render an ContactData for the ContactForm component
 *
 * @component
 */
const ContactData = () => {
  const style = useStyles();
  
  return (
    <Box className={style.container}>
      <Typography variant="h6" className={style.colorTitle} gutterBottom>
        COMUNÍCATE Y COLABORA
      </Typography>
      <Typography variant="subtitle2" className={style.colorSubtitle}>
        Sede Logística y Operaciones.
      </Typography>
      <Typography variant="body2" className={style.colorBody}>
        Av. Italia 6201, Edificio Los Robles, 1° piso,
      </Typography>
      <Typography variant="body2" className={style.colorBody}>
        Parque Tecnológico del LATU,
      </Typography>
      <Typography variant="body2" className={style.colorBody}>
        Montevideo, Uruguay.
      </Typography>
      <Box className={style.containerInfoContact}>
        <Box className={style.infoContact}>
          <WhatsAppIcon className={style.iconWhatssap} />
          <Link
            underline="hover"
            color="inherit"
            href="https://wa.me/59899026072"
          >
            (+598) 2603 8832
          </Link>
        </Box>
        <Box className={style.infoContact}>
          <EmailIcon className={style.iconEmail} />
          <Link
            underline="hover"
            color="inherit"
            href="mailto:tecnologia.aprendizaje@utec.edu.uy"
          >
            tecnologia.aprendizaje@utec.edu.uy
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactData;
