import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Navbar from 'components/rootComponents/Navbar/Navbar';
import { getToken } from 'services/user/user.service';
import { fetchUserInfo } from 'store/actions/navBarInfo/navBarAction';

/**
 * Page Navbar view, using [[Navbar]] component for user links and redux to retrieve user info (links)
 *
 * @component
 */
const PageNavbar = (props: any) => {
  const token = getToken();

  useEffect(() => {
    const getMenu = async () => {
      await props.fetchUserInfo(token);
    };

    getMenu();
  }, []);

  if (props.state && props.state.length > 0) {
    return (
      <Navbar isLoggedIn={props.isLoggedIn} navLinksList={props.state} />
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state: any) => {
  return { state: state.navBarInfo };
};

export default connect(mapStateToProps, { fetchUserInfo })(PageNavbar);
