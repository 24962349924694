import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';

import Chart from 'components/charts/Charts';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import useCharts from 'utils/customHooks/useCharts';
import { IGrupos } from './Grupos.interface';

const getSessionId = () => {
  return localStorage.getItem('sessionGroup') || '';
};

/**
 * Grupos view to display different [[Chart]] and [[ArticleList]]
 *
 * @component
 */
const Grupo = (props: IGrupos) => {
  const search = props.location.search;
  const id = new URLSearchParams(search).get('id') || getSessionId();
  localStorage.setItem('sessionGroup', id);

  // create custom Hook for articles
  const { isLoadingCharts, charts, title } = useCharts(
    window.location.pathname,
    props.filters,
    id,
  );

  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/grupos/graficas'}
        asignaturesUrl={'/v1.1/navegacion/grupos/listado'}
        listUrl={`/v1.1/navegacion/grupos/estudiantes?id=${id}`}
      />

      {isLoadingCharts && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      )}

      {!isLoadingCharts &&
        charts.map((chart, index) => {
          return (
            <Grid item key={index} xs={12}>
              <Chart {...chart} />
            </Grid>
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(Grupo);
