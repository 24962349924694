import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import { IStudentScholarshipData } from '../StudentScholarship.interface';
import { styles } from '../StudentScholarship.style';

const RemainingSubjectsTable = ({ data }: IStudentScholarshipData) => {
  const remainingSubjects = data.remainingSubjects;
  const sortedRemainingSubjects = Object.values(remainingSubjects).sort((a, b) => a.id - b.id);

  return (
    <>
      <Text
        style={styles.excersiceTitle}
      >
        U.Curriculares restantes del plan:
      </Text>
      {/* Table header */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={styles.tableContainer}>
          <View style={styles.container}>
            <Text style={styles.code}>Código</Text>
            <Text style={styles.descriptionHeaderExplain}>U.Curricular</Text>
          </View>
          {sortedRemainingSubjects.length > 0
            ? sortedRemainingSubjects.map((item, index) => {
              return (
                <View style={styles.row} key={`${item.id}_${item.name}_{${index}}`}>
                  <Text style={styles.code}>{item.id}</Text>
                  <Text style={styles.description}>{item.name}</Text>
                </View>
              );
            })
            : ''}
        </View>
      </View>

    </>
  );
};

export default RemainingSubjectsTable;
