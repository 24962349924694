import React from 'react';

import { CircularProgress, Grid } from '@mui/material';

import useStyles from './Spinner.styles';

const Spinner = () => {
  const classes = useStyles();
  
  return (
    <Grid
      item
      xs={12}
      className={classes.spinner}
    >
      <CircularProgress />
    </Grid>
  );
};

export default Spinner;
