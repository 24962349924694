import { makeStyles } from '@mui/styles';

export default makeStyles({
  container: {
    paddingTop: '20px',
    backgroundColor: '#333',
    marginTop: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'left',
    fontSize: '1rem',
  },
  containerDl: {
    color: '#fff',
    textAlign: 'left',
    listStyle: 'none',
    textDecoration: 'none',
  },
  title: {
    color: '#fff',
    marginBottom: '12px',
    fontWeight: 'bold',
  },
  urlItem: {
    paddingBottom: '8px',
    paddingTop: '8px',
    color: 'white',
  },
  item: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
