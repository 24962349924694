import { makeStyles } from '@mui/styles';

export default makeStyles({
  title: {
    margin: '24px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '28px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
});
