import { makeStyles } from '@mui/styles';

export default makeStyles({
  home: {
    fontFamily: 'utec_title',
    fontSize: '2em',
    color: 'white',
    verticalAlign: 'middle',
    margin: '0',
    padding: '0',
  },
  homeMobile: {
    fontFamily: 'utec_title',
    fontSize: '1.5em',
    verticalAlign: 'middle',
    margin: '0',
    padding: '0',
  },
});
