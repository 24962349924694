import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box } from '@mui/system';
import InboxIcon from '@mui/icons-material/Inbox';
import { CircularProgress, Grid, Paper } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';

import StudentScholarship from 'components/userProfileInfo/studentScholarship/StudentScholarship';
import UserProfileService from 'services/userProfile/userProfile.service';
import { IUserProfile } from '../Shared/UserProfile.interface';
import useStyles from '../Shared/UserProfile.styles';

/**
 * Scholarshio displays the student's data shows [[StudentScholarship]] component, and use [[UserProfileService]]
 *
 * @component PerfilEstudiante
 */

const PerfilEstudiante = (props: IUserProfile) => {
  const style = useStyles();
  const search = props.location.search;
  const id = new URLSearchParams(search).get('id') || '0';
  const plan = new URLSearchParams(search).get('plan') || '0';

  const empyInfo: any = [];
  const [scholarshipData, setScholarshipData] = useState(empyInfo);

  useEffect(() => {
    async function fetchStudentsProfile() {
      const scholarshipResult: any =
        await UserProfileService.getScholarshipData(id, plan);
      setScholarshipData(scholarshipResult);
    }

    fetchStudentsProfile();
  }, []);

  if (scholarshipData.length === 0) {
    return (
      <Grid item xs={12} className={style.loading}>
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {scholarshipData.grades.length ? (
          <PDFViewer width={'100%'} height={'1000px'} showToolbar={true}>
            <StudentScholarship data={scholarshipData} />
          </PDFViewer>
        ) : (
          <Paper elevation={3} className={style.paperNoData}>
            <Grid item xs={12} md={8}>
              <InboxIcon sx={{ fontSize: 30 }} />
              <div>
                No se encontraron datos suficientes del estudiante para expedir
                una escolaridad
              </div>
            </Grid>
          </Paper>
        )}
      </Box>
    );
  }
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(PerfilEstudiante);
