import React from 'react';

import { Grid } from '@mui/material';

import { Box } from '@mui/system';
import Chart from 'components/charts/Charts';
import { IActivityMoodleData } from './ActivityMoodle.interface';
import useStyles from './ActivityMoodle.style';
import ViewTitle from 'components/rootComponents/Standards/ViewTitle/ViewTitle';

/**
 * This component renders two tables with input data to moodle.
 * @component
 */
const ActivityMoodle = ({ data }: IActivityMoodleData) => {
  const style = useStyles();

  return (
    <Grid container /* sx={{ marginTop: 5 }} */ spacing={2}>
      <Grid item xs={12}>
        <Box className={style.title}>
          <ViewTitle title={'Actividad en moodle'} />
        </Box>
      </Grid>

      {data?.map((graph: any) => {
        return (
          <Grid item key={graph.name} xs={12} md={6}>
            <Chart
              data={graph.data}
              chartType={graph.chartTypeBar}
              name={graph.name}
              description={graph.description}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ActivityMoodle;
