import { makeStyles } from '@mui/styles';

export default makeStyles({
  title: {
    margin: '0',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#31374C',
  },
  article: {
    minWidth: '275px',
    border: '0.5px solid #DFE0EB',
  },
  ctaButton: {
    width: '100%',
    textDecoration: 'none',
  },
});
