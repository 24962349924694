import React from 'react';

import { Button } from '@mui/material';
import useStyles from './BlueButton.styles';
import { IBlueButton } from './BlueButton.interface';

/**
 * Standard blue button for CTA
 *
 * @component
 */
const BlueButton = ({ text, onClick }: IBlueButton) => {
  const classes = useStyles();

  return (
    <Button
      size="small"
      variant="contained"
      className={classes.button}
      onClick={onClick ? onClick : null}
    >
      {text}
    </Button>
  );
};

export default BlueButton;
