import React from 'react';

import { Grid } from '@mui/material';

import { Box } from '@mui/system';
import PaperBox from 'components/rootComponents/Standards/PaperBox/PaperBox';
import useStyles from './StudentInfo.style';
import ViewTitle from 'components/rootComponents/Standards/ViewTitle/ViewTitle';
import { IStudenInfoData } from './StudentInfo.interface';

/**
 * This component is rendered and displays the student's personal data.
 * @component
 * <ViewTitle title={'Perfil del Estudiante'} />
 */
const StudentInfo = ({ data }: IStudenInfoData) => {
  const style = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={style.title}>
          <ViewTitle title={''} /> 
        </Box>
      </Grid>
      {data &&
        data.map((infoPersonal: any, index: any) => {
          return (
            <Grid item xs={12} md={4} key={index}>
              <PaperBox title={infoPersonal.title} data={infoPersonal.data} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default StudentInfo;
