import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import BasicModal from 'components/rootComponents/Modal/BasicModal';
import video1 from 'assets/videos/FirstSteps_1.gif';
import video2 from 'assets/videos/FirstSteps_2.gif';
import video3 from 'assets/videos/FirstSteps_3.gif';


/**
 * Modal to render the first steps help
 * @component
 */
const FirstStepsModal = () => {
  const buttonName = 'Primeros pasos';

  return (
    <BasicModal title="Primeros pasos" description="" buttonTitle={buttonName}>
      <Carousel width={'100%'}>
        <div>
          <img src={video1} />
          <p className="legend">Elige el filtro en el panel de la izquierda y presiona el botón aplicar</p>
        </div>
        <div>
          <img src={video2} />
          <p className="legend">Navega entre las diferentes opciones del menú superior para ver datos.</p>
        </div>
        <div>
          <img src={video3} />
          <p className="legend">Puedes ver información detallada de cada reporte y utilizar mas filtros.</p>
        </div>
      </Carousel>
    </BasicModal>
  );
};

export default FirstStepsModal;
