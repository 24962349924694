import React from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';

import Chart from 'components/charts/Charts';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import useCharts from 'utils/customHooks/useCharts';
import { IHome } from './Home.interface';

/**
 * Home view where [[Chart]] graphs are rendered
 * depending on user preferences graphs are rendered or hidden
 *
 * @component
 */
const Home = (props: IHome) => {
  // const oddGraphs = props.dashboardSettings?.length % 2 !== 0;
  const title = 'Inicio';
  const { isLoadingCharts, charts } = useCharts(
    window.location.pathname,
    props.filters,
  );

  // Filter out charts with the name "prob of abandono"
  const filteredCharts = charts?.filter?.(
    (chart) => chart.name !== 'Probabilidad de abandono',
  );

  return (
    <Grid container spacing={2}>
      <ViewHeader title={title} />

      {!isLoadingCharts &&
        filteredCharts?.map?.((chart, index) => {
          return (
            <Grid item key={index} xs={12}>
              <Chart {...chart} />
            </Grid>
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(Home);
