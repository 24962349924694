import { makeStyles } from '@mui/styles';

export default makeStyles({
  checkboxContainer: {
    '& label': {
      width: '100%',
    },
  },
  checkboxLabel: {
    '& > span:nth-child(2)': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#31374c',
    },
  },
  scroll: {
    height: '510px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});
