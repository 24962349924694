import { StyleSheet, Font } from '@react-pdf/renderer';

const fontSize = 8;
const borderColor = '#90e5fc';
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

export const styles = StyleSheet.create({
  tableContainer: {
    fontSize,
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: '#bff0fd',
    marginBottom: 10,
  },
  listPage: {
    display: 'flex',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  signTitle: {
    width: '50%',
    alignSelf: 'center',
    fontSize: 12,
    textAlign: 'center',
    marginTop: 40,
  },
  signInput: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    margin: '40',
    textAlign: 'center',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  descriptionHeader: {
    width: '60%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  descriptionHeaderExplain: {
    width: '95%',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 9,
  },
  code: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
  },
  columnHeader: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  columnHeaderRight: {
    width: '20%',
  },

  column: {
    width: '20%',
    textAlign: 'center',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  columnRight: {
    width: '20%',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize,
    fontStyle: 'bold',
  },
  descriptionFooter: {
    width: '85%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: '10%',
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 10,
  },
  description: {
    width: '85%',
    textAlign: 'left',
    paddingLeft: 8,
    paddingRight: 8,
  },

  descriptionGrades: {
    width: '95%',
    alignSelf: 'center',
    textAlign: 'center',
  },

  courseName: {
    width: '60%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  acknowledgment: {
    marginTop: 5,
    textAlign: 'center',
    fontSize,
  },

  mainPage: {
    display: 'flex',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },

  logo: {
    flex: 3,
    alignSelf: 'center',
  },

  mainView: {
    backgroundColor: '#90e5fc',
    marginTop: 30,
    position: 'absolute',
    bottom: 150,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },

  studentView: {
    paddingVertical: 10,
    paddingHorizontal: 50,
  },

  studentHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 30,
  },

  studentHeaderView: {
    padding: 5,
    fontSize,
    backgroundColor: '#90e5fc',
    width: '50%',
  },

  studentHeaderText: {
    fontSize: 10,
    margin: 2,
    textAlign: 'left',
  },

  studentHeaderTextCapitalize: {
    fontSize: 10,
    margin: 2,
    textAlign: 'left',
    textTransform: 'capitalize',
  },

  title: {
    marginTop: '10',
    fontSize: 30,
    fontFamily: 'Oswald',
    color: '#009BDB',
    alignSelf: 'center',
  },

  coverText: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'left',
  },

  coverTextName: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'left',
    textTransform: 'capitalize',
  },

  subtitle: {
    fontSize: 18,
    fontFamily: 'Oswald',
    marginBottom: 10,
  },

  footerText: {
    flex: 1,
  },

  pageNumber: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  hrFooter: {
    border: '0.5px solid',
    borderRadius: 10,
    backgroundColor: 'black',
  },
  hrRounded: {
    border: '1px solid black',
    borderRadius: 10,
    backgroundColor: 'black',
  },

  imageHeader: {
    flex: 1,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: 15,
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },

  headerView: {
    flex: 4,
    fontSize: 20,
  },

  excersiceTitle: {
    fontSize: 10,
    marginBottom: 10,
    textAlign: 'left',
    textTransform: 'capitalize',
  },

  headerTitle: {
    flex: 1,
    fontSize: 20,
    alignSelf: 'flex-end',
    justifyContent: 'flex-start',
    color: 'black',
    fontFamily: 'Oswald',
  },

  headerSubtitle: {
    fontFamily: 'Oswald',
    alignSelf: 'flex-end',
    flex: 1,
    fontSize: 16,
    color: '#009BDB',
  },
});
