import { IFilter } from 'services/info/info.interface';

/**
 * Reducer for filters preferences
 *
 * @packageDocumentation
 */
export default (state: any = {}, action: any) => {
  switch (action.type) {
    case 'UPDATE_FILTERS': {
      if (state?.length > 0 && action.payload?.length > 0) {
        let result: IFilter[] = [];

        for (let index = 0; index < action.payload.length; index++) {
          const newFilter = action.payload[index];
          let memoryFilter = state.filter(
            (x: IFilter) => x.codigo === newFilter.codigo,
          )[0];

          if (memoryFilter === undefined) {
            memoryFilter = newFilter;
          } else {
            // only updated if the user is actively changing them
            if (memoryFilter.enabled && newFilter.enabled) {
              memoryFilter.valor = newFilter.valor;
              memoryFilter.valores = newFilter.valores;
            }
            memoryFilter.enabled = newFilter.enabled;
          }
          result.push(memoryFilter);
        }

        // if we have one in memory that is not on current filters, we just disable it
        // this is bringing errors where lists are not being returned by the backend, even if sending em as enabled=false
        // for (let index = 0; index < state.length; index++) {
        //   const element = state[index];
        //   let includesCode = action.payload.map(({codigo}: IFilter) => codigo).includes(element.codigo);
        //   if (!includesCode) {
        //     element.enabled = false;
        //     result.push(element);
        //   }
        // }
        
        return result;
      } else {
        return action.payload;
      }
    }
    case 'GET_FILTERS': {
      return state;
    }
    default:
      return state;
  }
};
