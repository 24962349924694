import { IDataSet } from 'components/charts/Charts.interface';
import { IHeaders } from 'components/list';
import { Chart as ChartJS, ChartType } from 'chart.js';
import jsPDF from 'jspdf';

type ChartRefType = React.RefObject<ChartJS<ChartType> | null>;

/**
 * Triggers the download of the chart as a PDF file.
 *
 * @param chartRef - Reference to the Chart.js instance.
 * @param fileName - Desired name for the downloaded PDF file.
 * @param orientation - PDF orientation ('portrait' or 'landscape'). Default is 'landscape'.
 * @param width - Width of the PDF in mm. Default is 280.
 * @param height - Height of the PDF in mm. Default is 150.
 */
export const downloadChartAsPDF = (
  chartRef: ChartRefType,
  fileName: string,
  orientation: 'portrait' | 'landscape' = 'landscape',
  width = 280,
  height = 150,
): void => {
  const imgData = getChartImage(chartRef);
  if (imgData) {
    const pdf = new jsPDF(orientation, 'mm', 'a4');
    pdf.addImage(imgData, 'PNG', 10, 10, width, height); // Adjust positioning and size as needed
    pdf.save(`${fileName}.pdf`);
  } else {
    console.error('Failed to generate chart image.');
  }
};

/**
 * Triggers the download of the chart as a PNG file.
 *
 * @param chartRef - Reference to the Chart.js instance.
 * @param fileName - Desired name for the downloaded PNG file.
 */
export const downloadChartAsPNG = (
  chartRef: ChartRefType,
  chartName: string,
): void => {
  const imgData = getChartImage(chartRef);
  if (!imgData) {
    return;
  }
  const link = document.createElement('a');
  link.href = imgData;
  link.download = `${chartName}.png`;
  link.click();
};

const getChartImage = (chartRef: ChartRefType) => {
  if (chartRef?.current) {
    return chartRef.current.toBase64Image();
  }
  return null;
};

/**
 * standarizes a given string by removing upper cases and diacritics
 * @param input string to be converted
 * @returns standarized string
 */
export const standarizeString = (input: string | undefined) => {
  if (input) {
    return input
      .toLowerCase()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '');
  }
  return '';
};

/**
 * standarizes a given string by removing upper cases and diacritics, it only works on strings and ignores numbers
 * specially designed to be used while sorting table data by asc|desc order
 * @param input string to be converted
 * @returns standarized string
 */
export const standarizeStringOnly = (input: string | number) => {
  if (input && typeof input === 'string') {
    return input
      .toLowerCase()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .trim();
  }
  return input;
};

/**
 * same as `generateAndDownloadCsv` but adding timestamp to filename
 */
export const generateAndDownloadCsvWithTimeStamp = (
  fileName: string,
  labels: string[],
  dataSet: IDataSet[],
) => {
  const date = new Date();
  const fileNameWithTimeStamp = `${fileName} - ${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  generateAndDownloadCsv(fileNameWithTimeStamp, labels, dataSet);
};

/**
 * generate and download a chart csv file onto the user's browser
 * @param fileName name of the file to download
 * @param labels column headers
 * @param dataSet column values
 */
export const generateAndDownloadCsv = (
  fileName: string,
  labels: string[],
  dataSet: IDataSet[],
) => {
  const csvContent = generateCsvContent(labels, dataSet);
  downloadCsv(fileName, csvContent);
};

/**
 * generates csv content data
 * @param labels column headers
 * @param dataSet column values
 * @returns chart data in csv format
 */
const generateCsvContent = (labels: string[], dataSet: IDataSet[]) => {
  let csvContent = 'data:text/csv;charset=utf-8,';

  csvContent += ','; // space for dataset label
  csvContent += `${labels.join(',')}\n`;
  dataSet.map((data: IDataSet) => {
    csvContent += `${data.label},`; // dataset label
    csvContent += `${data.data.join(',')}\n`;
  });

  return csvContent;
};

/**
 * generates and download csv content data form lists
 * @param labels column headers
 * @param dataSet column values
 * @param from List name
 * @returns void
 */
export const generateAndDownloadCsvContentList = (
  labels: IHeaders[],
  dataSet: any[],
  from: string,
) => {
  let csvContent = 'data:text/csv;charset=utf-8,';

  const columns = [];

  for (let index = 0; index < labels.length; index++) {
    csvContent += `${labels[index].label.replace(/,/g, '","')},`;
    columns.push(labels[index].id);
  }

  csvContent += '\n';

  for (let x = 0; x < dataSet.length; x++) {
    for (let c = 0; c < columns.length; c++) {
      csvContent += `${dataSet[x][columns[c]]
        .toString()
        .replace(/,/g, '","')},`;
    }
    csvContent += '\n';
  }

  const today = new Date().toLocaleDateString();

  downloadCsv(`Listado-${from}-${today}`, csvContent);
};

/**
 * sends file to user's browser
 * @param fileName name of the file to download
 * @param csvContent chart data in csv format
 */
const downloadCsv = (fileName: string, csvContent: any) => {
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);

  link.click();
};

/**
 *
 * @param date date in format 'dd de mmmmm de yyyy'. ie: '10 de enero de 2017'
 */
export const parseDate = (date: string) => {
  const dateParts = date.split(' ');
  // const day = Number.parseInt(dateParts[0]),
  //   month = parseStringMonthToNumber(dateParts[2]),
  //   year = Number.parseInt(dateParts[4]);

  return new Date(
    Number.parseInt(dateParts[4]),
    parseStringMonthToNumber(dateParts[2]),
    Number.parseInt(dateParts[0]),
  );
};

const parseStringMonthToNumber = (month: string) => {
  switch (month) {
    case 'enero':
      return 0;
    case 'febrero':
      return 1;
    case 'marzo':
      return 2;
    case 'abril':
      return 3;
    case 'mayo':
      return 4;
    case 'junio':
      return 5;
    case 'julio':
      return 6;
    case 'agosto':
      return 7;
    case 'septiembre':
      return 8;
    case 'octubre':
      return 9;
    case 'noviembre':
      return 10;
    case 'diciembre':
      return 11;
  }
  return 0;
};

/**
 * Checks if SSL is enabled
 */
export const isSslEnabled = () => {
  return process.env.REACT_APP_SSL_ENABLED == 'true';
};

/**
 * Gets the axios base url depending on the environment
 * @returns URL string for HTTP requests
 */
export const getAxiosBaseURL = () => {
  const ssl = isSslEnabled() ? 'https' : 'http';
  const port = process.env.REACT_APP_BACK_END_PORT ?? 4000;

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
    default:
      return `${ssl}://localhost:${port}`;
    case 'staging':
      return `${ssl}://${window.location.host}:${port}`;
    case 'production':
      return `${ssl}://aa.utec.edu.uy:${port}`;
  }
};

export const parseFullName = (firstName: string, lastName: string) => {
  return `${firstName.toLowerCase()} ${lastName.toLowerCase()}` || 'N/A';
};

export const parseDateToLocaleString = (date: Date) => {
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
