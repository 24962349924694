import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Grid, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { IHeaders } from 'components/list/ListHeaders/ListHeaders.interface';
import List from 'components/list/List';
import SearchInput from 'components/rootComponents/Standards/SearchInput/SearchInput';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import { IEncuestas } from '../Encuestas.interface';
import useLists from 'utils/customHooks/useLists';
import { generateAndDownloadCsvContentList } from 'utils/helper';

/**
 *
 * Encuestas view to display different [[Chart]] and [[List]]
 *
 * @component
 */
const EncuestasListadoUC = (props: IEncuestas) => {
  // Hooks
  const title = 'Encuestas';
  const searchPlaceholder = 'Buscar encuestas...';
  const {
    users,
    columns,
    isLoadingList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    search,
    setSearch,
    sortDirection,
    setSortDirection,
    totalPages,
  } = useLists('/v1.1/encuestasuc/docente/listadouc', props.filters);

  const headerCells: IHeaders[] = [
    {
      id: 'name',
      numeric: false,
      label: 'Nombre',
    },
  ];

  users[0]?.valores.forEach((valor: any, index: number) => {
    const isNumeric =
      valor.column === 'fecha_nacimiento' || valor.column === 'generacion';

    const formattedHeader: IHeaders = {
      id: valor.column,
      label: columns[index],
      numeric: isNumeric,
    };

    headerCells.push(formattedHeader);
  });

  const applicationsList = users.map((application: any) => {
    let formattedApplication = {
      name: application.nombre,
      link: application.__link,
    };

    for (let i = 0; i < application.valores.length; i++) {
      let column = application.valores[i].column;
      let value = application.valores[i].valor;

      formattedApplication = {
        ...formattedApplication,
        [column]: value,
        [i]: value,
      };
    }

    return formattedApplication;
  });

  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/encuestasuc/graficasuc'}
      />

      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <IconButton
          onClick={() =>
            generateAndDownloadCsvContentList(
              headerCells,
              applicationsList,
              title,
            )
          }
        >
          <DownloadIcon sx={{ color: '#007BFF' }} />
        </IconButton>
      </Grid>

      <Grid item xs={10} style={{ textAlign: 'right' }}>
        <SearchInput
          searchPlaceholder={searchPlaceholder}
          setSearchBar={setSearch}
        />
      </Grid>
      <Grid item xs={12}>
        <List
          search={search}
          headers={headerCells}
          rows={applicationsList}
          isLoading={isLoadingList}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPages={totalPages}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(EncuestasListadoUC);
