import React from 'react';

import { Card, CardActions, CardContent, Typography } from '@mui/material';

import useStyles from './Article.styles';
import BlueButton from 'components/rootComponents/Standards/BlueButton/BlueButton';
import { IData, IItem } from 'services/list/list.interface';
import { Link } from 'react-router-dom';

/**
 * private function for retrieving specific properties from subjects
 * @param propertyName property name
 * @returns property value
 */
export const getObjectPropertyValue = (
  propertyName: string,
  object: IData[],
) => {
  return object.find((obj: IData) => {
    return obj.tipo === propertyName;
  })?.valor;
};

/**
 * Article to display data with CTA button
 *
 * @component
 */
const Article = ({ nombre, __link, valores }: IItem) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.article}
      style={{ position: 'relative', minHeight: '275px', maxHeight: '275px' }}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          {getObjectPropertyValue('Asignatura', valores)}
        </Typography>
        <hr />
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {nombre}
        </Typography>
        <Typography variant="body2">
          {getObjectPropertyValue('ITR', valores)}
          <br />
          {getObjectPropertyValue('Ejercicio', valores)}
          <br />
          {getObjectPropertyValue('carrera', valores)}
        </Typography>
      </CardContent>
      <CardActions style={{ position: 'absolute', bottom: '0', width: '95%' }}>
        <Link to={__link} className={classes.ctaButton}>
          <BlueButton text="Entrar" />
        </Link>
      </CardActions>
    </Card>
  );
};

export default Article;
