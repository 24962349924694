import { makeStyles } from '@mui/styles';

export default makeStyles({
  spinner: {
    display: 'flex',
    height: '20vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
