import React from 'react';

import { Box, Typography } from '@mui/material';

import { IHeaders } from './Headers.interface';

/**
 * Component for login headers box
 *
 * @component
 */
const Headers = ({ title, subtitle }: IHeaders) => {
  return (
    <Box>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
    </Box>
  );
};

export default Headers;
