import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';

import Chart from 'components/charts/Charts';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import useCharts from 'utils/customHooks/useCharts';
import { ICalificaciones } from './Calificaciones.interface';

/**
 *
 * Estudiantes view to display different [[Chart]] and [[List]]
 *
 * @component
 */
const Calificaciones = (props: ICalificaciones) => {
  // Hooks
  const { isLoadingCharts, charts } = useCharts(
    window.location.pathname,
    props.filters,
  );
  const title = 'Estudiantes';

  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/estudiantes/calificaciones'}
      />

      {isLoadingCharts && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      )}

      {!isLoadingCharts &&
        charts.map((chart, index) => {
          return (
            <Grid item key={index} xs={12}>
              <Chart {...chart} />
            </Grid>
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(Calificaciones);
