import React from 'react';
import logo from '../../../assets/img/06-isologotipo-para-fondo-blanco.png';

import { Text, View, Image} from '@react-pdf/renderer';
import {styles} from './StudentScholarship.style';

/**
 * this component renders all the student's personal data
 * @component
 */

const Header = () => {
  return (
    <View style={styles.header} fixed>
      <Image style={styles.imageHeader} src={logo} />
      <View style={styles.headerView}>
        <Text style={styles.headerTitle}>UNIVERSIDAD TECNOLÓGICA</Text>
        <hr style={styles.hrRounded} />
        <Text style={styles.headerSubtitle}>Escolaridad</Text>
      </View>
    </View>
  );
};

export default Header;
