import React from 'react';

import { CardMedia, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import useStyles from './ContactForm.styles';
import ContactData from '../ContactData/ContactData';
import contactImage from 'assets/img/bg-contacto.jpeg';

/**
 * Component used to render an ContactForm for the Contact view
 *
 * @component
 */
const ContactForm = () => {
  /* Style Hook */
  const style = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <CardMedia
            component="img"
            image={contactImage}
            alt="Contacto"
          />
          <Typography className={style.titleContact}>Contacto</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={style.container}>
          {/* Data Contact   */}
          <ContactData />

          <Box className={style.containerForm}>
            <iframe
              className={style.containerForm}
              src="https://taa.utec.edu.uy/3973-2/"
              frameBorder="0"
              style={{ width: '100%', height: '900px' }}
            ></iframe>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
