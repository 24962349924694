/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'utils/axios';
import { IFilter } from 'services/info/info.interface';
import { getToken } from 'services/user/user.service';
import { IResponse } from './list.interface';

/**
 * Service for [[ArticleList]]
 */
const ListService = {
  /**
   *
   * @param token user's token
   * @returns graph data ready to render
   */
  getItems: async (
    url: string,
    page: number,
    pageSize: number,
    filters: IFilter[],
    abortController: any,
  ): Promise<IResponse> => {
    const token = getToken();

    const response: any = await axios.post(
      `${url}?access_token=${token}&page=${page}&page_size=${pageSize}&column=&order=&search=`,
      filters,
      {
        signal: abortController,
      },
    );

    return {
      items: response.data.__data,
      pageTotal: response.data.__meta.page_total,
    };
  },

  getListData: async (
    url: string,
    page: number,
    pageSize: number,
    id = '',
    column = '',
    order = '',
    search: string,
    filters: IFilter[],
    abortController: any,
  ) => {
    const token = getToken();
    const response: any = await axios.post(
      `${url}?access_token=${token}&page=${page}&page_size=${pageSize}&id=${id}&column=${column}&order=${order}&search=${search}`,
      filters,
      {
        signal: abortController,
      },
    );

    if (response.status === 200 && response.data.__data) {
      return {
        users: response.data.__data,
        columns: response.data.__meta.columnas,
        totalPages: response.data.__meta.page_total,
      };
    }
    return {};
  },
};

export default ListService;
