import React from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';

import ArticleList from 'components/articleList/ArticleList';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import SearchInput from 'components/rootComponents/Standards/SearchInput/SearchInput';
import useArticles from 'utils/customHooks/useArticles';
import { IGrupos } from '../Grupos.interface';

/**
 * /**
 * Grupos view to display different [[Chart]] and [[ArticleList]]
 *
 * @component
 */
const GruposListado = (props: IGrupos) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(24);
  const [searchBar, setSearchBar] = React.useState('');
  const { articles, isLoading } = useArticles('v1.1/grupos/listado', props.filters, 1000);

  const title = 'Grupos';
  const searchPlaceholder = 'Buscar grupo...';

  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/grupos/graficas'}
        asignaturesUrl={'/v1.1/navegacion/grupos/listado'}
      />

      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <SearchInput
          searchPlaceholder={searchPlaceholder}
          setSearchBar={setSearchBar}
        />
      </Grid>
      <Grid item xs={12}>
        <ArticleList
          isLoading = {isLoading}
          search={searchBar}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          articles={articles}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(GruposListado);
