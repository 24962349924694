/**
 * Reducer to retrieve user's navbar information
 * 
 * @packageDocumentation
 */
export default (state: any = {}, action: any) => {
  switch (action.type) {
    case 'FETCH_USERINFO': {
      return action.payload;
    }
    default:
      return state;
  }
};
