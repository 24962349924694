import { IHeaders } from './ListHeaders/ListHeaders.interface';

/**
 * Interface for [[ListHeaders]] component.
 */
export interface IList {
  headers: IHeaders[];
  /**
   * Array of items to display data.
   *
   * Has to contain the same variable name as Headers in order to match and sort the table.
   *
   * Example:
   * ```
   * const rows: {
   * name: string;
   * age: number;
   * major: string;
   * itr: string;
   * generation: number;
   * probabilityOfQuitting: number;
   * }[]
   * ```
   */
  rows: any;
  search: string;
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  totalPages: number;
  sortColumn: string;
  setSortColumn: (sortColumn: string) => void;
  sortDirection: IOrder;
  setSortDirection: (sortDirection: IOrder) => void;
}

/**
 * Enum for sorting list as Ascending or Descending order.
 * @enum
 */
export enum IOrder {
  /**
   * Ascending
   */
  ASC = 'asc',
  /**
   * Descending
   */
  DESC = 'desc',
}
