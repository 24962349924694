import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';

import logo from '../../../assets/img/06-isologotipo-para-fondo-blanco.png';
import { IStudentScholarshipData } from './StudentScholarship.interface';
import { styles } from './StudentScholarship.style';
import { parseDateToLocaleString } from 'utils/helper';

/**
 * this component renders the cover page with student profile data into scholarship pdf.
 * @component
 */

const CoverPage = ({ data }: IStudentScholarshipData) => {
  let fullName = 'N/A';

  if (data.name && data.lastname) {
    fullName = `${data.name.toLowerCase()} ${data.lastname.toLowerCase()}`;
  }

  return (
    <Page size="A4" style={styles.mainPage}>
      <View style={styles.logo}>
        <Image style={styles.image} src={logo} />
        <Text style={styles.title}>ESCOLARIDAD</Text>
      </View>
      <View style={styles.mainView}>
        <View style={styles.studentView}>
          <Text style={styles.subtitle}>Datos del Estudiante:</Text>
          <Text style={styles.coverTextName}>Nombre Completo: {fullName}</Text>
          <Text style={styles.coverText}>
            Documento de Identidad: {data.ci}
          </Text>
          <Text style={styles.coverText}>Cohorte: {data.admission_year}</Text>
          <Text style={styles.coverText}>Plan de Estudios: {data.career}</Text>
          <Text style={styles.coverText}>Sede: {data.itr}</Text>
          <Text style={styles.coverText}>
            Fecha de Expedición: {parseDateToLocaleString(new Date())}
          </Text>
        </View>
      </View>
    </Page>
  );
};

export default CoverPage;
