import { makeStyles } from '@mui/styles';

export default makeStyles({
  paperContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '24px',
    height: '128px',
    borderRadius: '12px',
  },
  title: {
    margin: '2%',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '28px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  titlePaper: {
    fontWeight: 'bold',
    alignSelf: 'normal',
    fontSize: '16px',
    color: 'black',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: '#F2F8FF',
    padding: '8px',
    borderRadius: '8px',
  },
  iconColor: {
    color: '#007BFF',
  },
  containerIconAndData: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
  },
  dataTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  courseItem: {
    marginTop: '2%',
    marginBottom: '2%',
  },
});
