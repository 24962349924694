import { makeStyles } from '@mui/styles';

export default makeStyles({
  title: {
    margin: '24px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '28px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  paperContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    maxHeight: '30vh',
    margin: '15px',
    borderRadius: '12px',
  },
  containerData: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    width: '80%',
    margin: '10%',
  },
  titlePaper: {
    fontWeight: 'bold',
    color: '#777D92',
    fontSize: '14px',
  },
  dataTitle: {
    padding: '2px',
    lineHeight: '24px',
    fontSize: '15px',
  },
});
