import axios from 'axios';
import { getAxiosBaseURL } from './helper';

/**
 * Base declaration for HTTPS requests against UTEC Backend
 */
const mainAxios = axios.create({
  baseURL: getAxiosBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    cookie: document.cookie,
  },
});

mainAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

/**
 * Login url
 */
export const ldapAxios = axios.create({
  baseURL: 'https://aa.utec.edu.uy:4000',
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    cookie: document.cookie,
  },
});

export default mainAxios;
