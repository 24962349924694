import React, { ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@mui/material';
import { IEncuestas } from '../Encuestas.interface';
const pdfUrls = {
  iagro: `${process.env.PUBLIC_URL}/reportes/iagro.pdf`,
  iagua: `${process.env.PUBLIC_URL}/reportes/IAGUA.pdf`,
  ibio: `${process.env.PUBLIC_URL}/reportes/IBIO.pdf`,
  ica: `${process.env.PUBLIC_URL}/reportes/ICA.pdf`,
  ier: `${process.env.PUBLIC_URL}/reportes/IER.pdf`,
  ilogfb: `${process.env.PUBLIC_URL}/reportes/ILOG Fray Bentos.pdf`,
  ilogr: `${process.env.PUBLIC_URL}/reportes/ILOG Rivera.pdf`,
  imec: `${process.env.PUBLIC_URL}/reportes/IMEC.pdf`,
  laa: `${process.env.PUBLIC_URL}/reportes/LAA.pdf`,
  lctl: `${process.env.PUBLIC_URL}/reportes/LCTL.pdf`,
  ljmc: `${process.env.PUBLIC_URL}/reportes/LJMC.pdf`,
  ltid: `${process.env.PUBLIC_URL}/reportes/LTI Durazno.pdf`,
  ltifb: `${process.env.PUBLIC_URL}/reportes/LTI Fray Bentos.pdf`,
  ltimm: `${process.env.PUBLIC_URL}/reportes/LTI Melo Minas.pdf`,
  tcam: `${process.env.PUBLIC_URL}/reportes/TCAM.pdf`,
  tim: `${process.env.PUBLIC_URL}/reportes/TIM.pdf`,
  tinfm: `${process.env.PUBLIC_URL}/reportes/TINF Maldonado.pdf`,
  tinfp: `${process.env.PUBLIC_URL}/reportes/TINF Paysandú.pdf`,
  tinfsj: `${process.env.PUBLIC_URL}/reportes/TINF San José.pdf`,
  tmspl: `${process.env.PUBLIC_URL}/reportes/TMSPL.pdf`,
  tquim: `${process.env.PUBLIC_URL}/reportes/TQUIM.pdf`,
  iagro1s: `${process.env.PUBLIC_URL}/reportes/1S2023-IAGRO.pdf`,
  iagua1s: `${process.env.PUBLIC_URL}/reportes/1S2023-IAGUA.pdf`,
  ibio1s: `${process.env.PUBLIC_URL}/reportes/1S2023-IBIO.pdf`,
  ica1s: `${process.env.PUBLIC_URL}/reportes/1S2023-ICA.pdf`,
  ier1s: `${process.env.PUBLIC_URL}/reportes/1S2023-IER.pdf`,
  ilogfb1s: `${process.env.PUBLIC_URL}/reportes/1S2023-ILOGFB.pdf`,
  ilogr1s: `${process.env.PUBLIC_URL}/reportes/1S2023-ILOGR.pdf`,
  imec1s: `${process.env.PUBLIC_URL}/reportes/1S2023-IMEC.pdf`,
  laa1s: `${process.env.PUBLIC_URL}/reportes/1S2023-LAA.pdf`,
  lctl1s: `${process.env.PUBLIC_URL}/reportes/1S2023-LCTL.pdf`,
  ljmc1s: `${process.env.PUBLIC_URL}/reportes/1S2023-LJMC.pdf`,
  ltid1s: `${process.env.PUBLIC_URL}/reportes/1S2023-LTID.pdf`,
  ltifb1s: `${process.env.PUBLIC_URL}/reportes/1S2023-LTIFB.pdf`,
  ltimm1s: `${process.env.PUBLIC_URL}/reportes/1S2023-LTIM-LTIMinas.pdf`,
  tcam1s: `${process.env.PUBLIC_URL}/reportes/1S2023-TCAM.pdf`,
  tinfm1s: `${process.env.PUBLIC_URL}/reportes/1S2023-TINFM.pdf`,
  tinfp1s: `${process.env.PUBLIC_URL}/reportes/1S2023-TINFP.pdf`,
  tinfsj1s: `${process.env.PUBLIC_URL}/reportes/1S2023-TINFSJ.pdf`,
  iagro2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-IAGRO.pdf`,
  iagua2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-IAGUA.pdf`,
  ibio2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-IBIO.pdf`,
  ica2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-ICA.pdf`,
  ier2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-IER.pdf`,
  ilogfb2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-ILOGFB.pdf`,
  ilogrivera2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-ILOGRIVERA.pdf`,
  imec2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-IMEC.pdf`,
  laa2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-LAA.pdf`,
  lctl2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-LCTL.pdf`,
  ljmc2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-LJMC.pdf`,
  ltifb2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-LTIFB.pdf`,
  ltidurazno2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-LTIDZ.pdf`,
  ltimelo2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-LTIMELO.pdf`,
  ltiminas2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-LTIMINAS.pdf`,
  tcam2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-TCAM.pdf`,
  tinpaysandu2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-TINFPDU.pdf`,
  tinmaldonado2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-TINFMAL.pdf`,
  tinsanjose2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-TINFSJ.pdf`,
  tmspl2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-TMSPL.pdf`,
  tquimico2s23: `${process.env.PUBLIC_URL}/reportes/2S-2023-TQ.pdf`,
};
type PdfLinksType = {
  [key: string]: {
    pdfsurl?: string;
    pdf1surl?: string;
    pdf2surl?: string;
    pdf3surl?: string;
    pdf21surl?: string;
    pdf31surl?: string;
    pdf2s23url?: string;
    pdf2s24url?: string;
    pdf2s25url?: string;
    pdf2s26url?: string;
    pdfCoordDir1?: string;
    pdfCoordDir2?: string;
    pdfCoordDir3?: string;
    pdfCoordDir4?: string;
    pdfCoordDir5?: string;
    pdfCoordDir6?: string;
    pdfCoordDir1231s?: string;
    pdfCoordDir2231s?: string;
    pdfCoordDir3231s?: string;
    pdfCoordDir1232s?: string;
    pdfCoordDir2232s?: string;
    pdfCoordDir3232s?: string;
  };
};
const getSessionId = () => {
  return localStorage.getItem('sessionGroup') || '';
};
const EncuestasCoord = (props: IEncuestas) => {
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const handleYearButtonClick = (year: number) => {
    setSelectedYear(year);
  };

  const segments = props.location.pathname.split('/');
  const id = segments[segments.length - 1] || getSessionId();
  const pdfLinks: PdfLinksType = {
    'pruebas.upd': {
      pdfsurl: pdfUrls.iagro,
      pdf1surl: pdfUrls.iagro1s,
      pdf2s23url: pdfUrls.iagro2s23,
    },
    'johan.duque': {
      pdfsurl: pdfUrls.iagro,
      pdf1surl: pdfUrls.iagro1s,
      pdf2s23url: pdfUrls.iagro2s23,
    },
    'ella.escandon': {
      pdfsurl: pdfUrls.ibio,
      pdf1surl: pdfUrls.ibio1s,
      pdf2s23url: pdfUrls.ibio2s23,
    },
    'ernesto.pecoits': {
      pdfsurl: pdfUrls.iagua,
      pdf1surl: pdfUrls.iagua1s,
      pdf2s23url: pdfUrls.iagua2s23,
      pdfCoordDir1: pdfUrls.ier,
      pdfCoordDir2: pdfUrls.iagro,
      pdfCoordDir1231s: pdfUrls.ier1s,
      pdfCoordDir1232s: pdfUrls.ier2s23,
      pdfCoordDir2231s: pdfUrls.iagro1s,
      pdfCoordDir2232s: pdfUrls.iagro2s23,
    },
    'wellington.mezzomo': {
      pdfsurl: pdfUrls.iagua,
      pdf1surl: pdfUrls.iagua1s,
      pdf2s23url: pdfUrls.iagua2s23,
    },
    wellingtonmezzomo: {
      pdfsurl: pdfUrls.iagua,
      pdf1surl: pdfUrls.iagua1s,
      pdf2s23url: pdfUrls.iagua2s23,
    },

    'ernesto.pecotis': {
      pdfsurl: pdfUrls.iagua,
      pdf1surl: pdfUrls.iagua1s,
      pdf2s23url: pdfUrls.iagua2s23,
      pdfCoordDir1: pdfUrls.ier,
      pdfCoordDir2: pdfUrls.iagro,
      pdfCoordDir1231s: pdfUrls.ier1s,
      pdfCoordDir1232s: pdfUrls.ier2s23,
      pdfCoordDir2231s: pdfUrls.iagro1s,
      pdfCoordDir2232s: pdfUrls.iagro2s23,
    },
    'cristiano.schuster': {
      pdfsurl: pdfUrls.ica,
      pdf1surl: pdfUrls.ica1s,
      pdf2s23url: pdfUrls.ica2s23,
    },
    'marcelo.aguiar': {
      pdfsurl: pdfUrls.ier,
      pdf1surl: pdfUrls.ier1s,
      pdf2s23url: pdfUrls.ier2s23,
    },
    'dahiana.silva': {
      pdfsurl: pdfUrls.ilogfb,
      pdf1surl: pdfUrls.ilogfb1s,
      pdf2s23url: pdfUrls.ilogfb2s23,
    },
    'nolan.sanchez': {
      pdfsurl: pdfUrls.ilogfb,
      pdf1surl: pdfUrls.ilogfb1s,
      pdf2s23url: pdfUrls.ilogfb2s23,
    },
    nolanst: {
      pdfsurl: pdfUrls.ilogfb,
      pdf1surl: pdfUrls.ilogfb1s,
      pdf2s23url: pdfUrls.ilogfb2s23,
    },
    'jackson.bartz': {
      pdfsurl: pdfUrls.ilogr,
      pdf1surl: pdfUrls.ilogr1s,
      pdf2s23url: pdfUrls.ilogrivera2s23,
    },
    'yamile.lara': {
      pdfsurl: pdfUrls.imec,
      pdf1surl: pdfUrls.imec1s,
      pdf2s23url: pdfUrls.imec2s23,
    },
    'annabela.estevez': {
      pdfsurl: pdfUrls.laa,
      pdf2surl: pdfUrls.tcam,
      pdf1surl: pdfUrls.laa1s,
      pdf21surl: pdfUrls.tcam1s,
      pdf2s23url: pdfUrls.laa2s23,
      pdf2s24url: pdfUrls.tcam2s23,
    },
    'nora.techeira': {
      pdfsurl: pdfUrls.lctl,
      pdf1surl: pdfUrls.lctl1s,
      pdf2s23url: pdfUrls.lctl2s23,
    },

    'tomas.lopez': {
      pdfsurl: pdfUrls.lctl,
      pdfCoordDir1: pdfUrls.laa,
      pdfCoordDir2: pdfUrls.tquim,
      pdfCoordDir3: pdfUrls.tmspl,
      pdf1surl: pdfUrls.lctl1s,
      pdfCoordDir1231s: pdfUrls.laa1s,
      pdf2s23url: pdfUrls.lctl2s23,
      pdfCoordDir1232s: pdfUrls.laa2s23,
      pdfCoordDir2232s: pdfUrls.tquimico2s23,
      pdfCoordDir3232s: pdfUrls.tmspl2s23,
      // nuevos
    },

    vivitodt: {
      pdfsurl: pdfUrls.ltid,
      pdf2surl: pdfUrls.ltifb,
      pdf3surl: pdfUrls.ltimm,
      pdfCoordDir1: pdfUrls.tinfm,
      pdfCoordDir2: pdfUrls.tinfp,
      pdfCoordDir3: pdfUrls.tinfsj,
      pdf1surl: pdfUrls.ltid1s,
      pdf21surl: pdfUrls.ltidurazno2s23,
      pdf31surl: pdfUrls.ltifb1s,
      pdf2s23url: pdfUrls.ltifb2s23,
      pdf2s24url: pdfUrls.ltimm1s,
      pdf2s25url: pdfUrls.ltiminas2s23,
      pdf2s26url: pdfUrls.ltimelo2s23,
      pdfCoordDir1231s: pdfUrls.tinfm1s,
      pdfCoordDir2231s: pdfUrls.tinmaldonado2s23,
      pdfCoordDir3231s: pdfUrls.tinfp1s,
      pdfCoordDir1232s: pdfUrls.tinpaysandu2s23,
      pdfCoordDir2232s: pdfUrls.tinfsj1s,
      pdfCoordDir3232s: pdfUrls.tinsanjose2s23,
    },

    'viviane.todt': {
      pdfsurl: pdfUrls.ltid,
      pdf2surl: pdfUrls.ltifb,
      pdf3surl: pdfUrls.ltimm,
      pdfCoordDir1: pdfUrls.tinfm,
      pdfCoordDir2: pdfUrls.tinfp,
      pdfCoordDir3: pdfUrls.tinfsj,
      pdf1surl: pdfUrls.ltid1s,
      pdf21surl: pdfUrls.ltidurazno2s23,
      pdf31surl: pdfUrls.ltifb1s,
      pdf2s23url: pdfUrls.ltifb2s23,
      pdf2s24url: pdfUrls.ltimm1s,
      pdf2s25url: pdfUrls.ltiminas2s23,
      pdf2s26url: pdfUrls.ltimelo2s23,
      pdfCoordDir1231s: pdfUrls.tinfm1s,
      pdfCoordDir2231s: pdfUrls.tinmaldonado2s23,
      pdfCoordDir3231s: pdfUrls.tinfp1s,
      pdfCoordDir1232s: pdfUrls.tinpaysandu2s23,
      pdfCoordDir2232s: pdfUrls.tinfsj1s,
      pdfCoordDir3232s: pdfUrls.tinsanjose2s23,
    },
    gmsalum: {
      pdfsurl: pdfUrls.ilogfb,
      pdf2surl: pdfUrls.ilogr,
      pdf3surl: pdfUrls.imec,
      pdfCoordDir1: pdfUrls.ica,
      pdfCoordDir2: pdfUrls.ibio,
      pdfCoordDir3: pdfUrls.tim,
      pdf1surl: pdfUrls.ilogfb1s,
      pdf21surl: pdfUrls.ilogfb2s23,
      pdf31surl: pdfUrls.ilogr1s,
      pdf2s23url: pdfUrls.ilogrivera2s23,
      pdf2s24url: pdfUrls.ica1s,
      pdf2s25url: pdfUrls.ica2s23,
      pdfCoordDir1232s: pdfUrls.ibio1s,
      pdfCoordDir2232s: pdfUrls.ibio2s23,
      pdfCoordDir1231s: pdfUrls.imec1s,
      pdfCoordDir2231s: pdfUrls.imec2s23,
    },
    'graciela.salum': {
      pdfsurl: pdfUrls.ilogfb,
      pdf2surl: pdfUrls.ilogr,
      pdf3surl: pdfUrls.imec,
      pdfCoordDir1: pdfUrls.ica,
      pdfCoordDir2: pdfUrls.ibio,
      pdfCoordDir3: pdfUrls.tim,
      pdf1surl: pdfUrls.ilogfb1s,
      pdf21surl: pdfUrls.ilogfb2s23,
      pdf31surl: pdfUrls.ilogr1s,
      pdf2s23url: pdfUrls.ilogrivera2s23,
      pdf2s24url: pdfUrls.ica1s,
      pdf2s25url: pdfUrls.ica2s23,
      pdfCoordDir1232s: pdfUrls.ibio1s,
      pdfCoordDir2232s: pdfUrls.ibio2s23,
      pdfCoordDir1231s: pdfUrls.imec1s,
      pdfCoordDir2231s: pdfUrls.imec2s23,
    },
    'federico.lazzarini': {
      pdfsurl: pdfUrls.ljmc,
      pdf1surl: pdfUrls.ljmc1s,
      pdf2s23url: pdfUrls.ljmc2s23,
    },
    'alejandra.martinez': {
      pdfsurl: pdfUrls.ljmc,
      pdf1surl: pdfUrls.ljmc1s,
      pdf2s23url: pdfUrls.ljmc2s23,
    },
    'monica.silvestri': {
      pdfsurl: pdfUrls.ltid,
      pdf2surl: pdfUrls.ltifb,
      pdf3surl: pdfUrls.ltimm,
      pdf1surl: pdfUrls.ltid1s,
      pdf21surl: pdfUrls.ltifb1s,
      pdf31surl: pdfUrls.ltimm1s,
      pdf2s23url: pdfUrls.ltidurazno2s23,
      pdf2s24url: pdfUrls.ltifb2s23,
      pdf2s25url: pdfUrls.ltiminas2s23,
      pdf2s26url: pdfUrls.ltimelo2s23,
    },
    'mauro.appratto': { pdfsurl: pdfUrls.tim },
    'reinaldo.diaz': {
      pdfsurl: pdfUrls.tinfm,
      pdf1surl: pdfUrls.tinfm1s,
      pdf2s23url: pdfUrls.tinmaldonado2s23,
    },
    'ana.iruleguy': {
      pdfsurl: pdfUrls.tinfp,
      pdf1surl: pdfUrls.tinfp1s,
      pdf2s23url: pdfUrls.tinpaysandu2s23,
    },
    'cristian.bauza': {
      pdfsurl: pdfUrls.tinfsj,
      pdf1surl: pdfUrls.tinfsj1s,
      pdf2s23url: pdfUrls.tinsanjose2s23,
    },
    'rocio.martinez.1': {
      pdfsurl: pdfUrls.tmspl,
      pdf2s23url: pdfUrls.tmspl2s23,
    },
    'ricardo.hladki': {
      pdfsurl: pdfUrls.tquim,
      pdf2s23url: pdfUrls.tquimico2s23,
    },
    'silvia.belvisi': {
      pdfsurl: pdfUrls.tinfm,
      pdf2surl: pdfUrls.tinfp,
      pdf3surl: pdfUrls.tinfsj,
      pdfCoordDir1: pdfUrls.tquim,
      pdfCoordDir2: pdfUrls.tim,
      pdf1surl: pdfUrls.tinfm1s,
      pdf21surl: pdfUrls.tinfp1s,
      pdf31surl: pdfUrls.tinfsj1s,
      pdf2s23url: pdfUrls.tinmaldonado2s23,
      pdf2s24url: pdfUrls.tinpaysandu2s23,
      pdf2s25url: pdfUrls.tinsanjose2s23,
      pdfCoordDir1232s: pdfUrls.tquimico2s23,
    },
  };
  let enlaceTexto = 'Descargar';
  let enlacePdf22Dur2s = '';
  let enlacePdf23Dur1s = '';
  let enlacePdf22Pay2s = '';
  let enlacePdf23Pay1s = '';
  let enlacePdf22MeloMinas2s = '';
  let enlacePdf23MeloMinas1s = '';
  let enlacePdf2s23 = '';
  let enlacePdf2s24 = '';
  let enlacePdf2s25 = '';
  let enlacePdf2s26 = '';
  let enlaceCoordDir1 = '';
  let enlaceCoordDir2 = '';
  let enlaceCoordDir3 = '';
  let enlaceCoordDir1231s = '';
  let enlaceCoordDir1232s = '';
  let enlaceCoordDir2231s = '';
  let enlaceCoordDir3231s = '';
  let enlaceCoordDir2232s = '';
  let enlaceCoordDir3232s = '';

  const pdfInfo = pdfLinks[id];

  if (pdfInfo) {
    enlacePdf22Dur2s = pdfInfo.pdfsurl || '';
    enlacePdf23Dur1s = pdfInfo.pdf1surl || '';
    enlacePdf22Pay2s = pdfInfo.pdf2surl || '';
    enlacePdf23Pay1s = pdfInfo.pdf21surl || '';
    enlacePdf22MeloMinas2s = pdfInfo.pdf3surl || '';
    enlacePdf23MeloMinas1s = pdfInfo.pdf31surl || '';
    enlacePdf2s23 = pdfInfo.pdf2s23url || '';
    enlacePdf2s24 = pdfInfo.pdf2s24url || '';
    enlacePdf2s25 = pdfInfo.pdf2s25url || '';
    enlacePdf2s26 = pdfInfo.pdf2s26url || '';
    enlaceCoordDir1 = pdfInfo.pdfCoordDir1 || '';
    enlaceCoordDir2 = pdfInfo.pdfCoordDir2 || '';
    enlaceCoordDir3 = pdfInfo.pdfCoordDir3 || '';
    enlaceCoordDir1231s = pdfInfo.pdfCoordDir1231s || '';
    enlaceCoordDir1232s = pdfInfo.pdfCoordDir1232s || '';
    enlaceCoordDir2231s = pdfInfo.pdfCoordDir2231s || '';
    enlaceCoordDir2232s = pdfInfo.pdfCoordDir2232s || '';
    enlaceCoordDir3231s = pdfInfo.pdfCoordDir3231s || '';
    enlaceCoordDir3232s = pdfInfo.pdfCoordDir3232s || '';
  }
  const handleDownloadClick = (pdfSrc: string) => {
    const link = document.createElement('a');
    link.href = pdfSrc;
    link.setAttribute('download', pdfSrc.split('/').pop() || '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const PdfViewerButton: React.FC<any> = ({ pdfSrc, handleDownloadClick }) => (
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      {pdfSrc ? (
        <iframe title="PDF Viewer" src={pdfSrc} width="100%" height="500px" />
      ) : (
        <p></p>
      )}
      {pdfSrc && (
        <Button onClick={handleDownloadClick} variant="contained">
          Descargar ({pdfSrc ? pdfSrc.split('/').pop() : ''})
        </Button>
      )}
    </Grid>
  );

  const YearButton: React.FC<{ year: number; onClick: () => void }> = ({
    year,
    onClick,
  }) => (
    <Grid item xs={6} style={{ textAlign: 'center' }}>
      <Button onClick={onClick} variant="contained">
        {year}
      </Button>
    </Grid>
  );

  const pdfSrcs2022 = pdfInfo
    ? [
        enlacePdf22Dur2s || '',
        enlacePdf22Pay2s || '',
        enlacePdf22MeloMinas2s || '',
        enlaceCoordDir1 || '',
        enlaceCoordDir2 || '',
        enlaceCoordDir3 || '',
      ]
    : [];

  const pdfSrcs2023 = pdfInfo
    ? [
        enlacePdf23Dur1s || '',
        enlacePdf23Pay1s || '',
        enlacePdf23MeloMinas1s || '',
        enlacePdf2s23 || '',
        enlacePdf2s24 || '',
        enlacePdf2s25 || '',
        enlacePdf2s26 || '',
        enlaceCoordDir1231s || '',
        enlaceCoordDir2231s || '',
        enlaceCoordDir3231s || '',
        enlaceCoordDir1232s || '',
        enlaceCoordDir2232s || '',
        enlaceCoordDir3232s || '',
      ]
    : [];

  const renderPdfViewerButtons = (pdfSrcs: string[] | undefined): ReactNode => {
    return (
      <Grid
        container
        spacing={2}
        style={{ alignItems: 'center', marginTop: '0px' }}
      >
        {pdfSrcs?.map((pdfSrc, index) => (
          <PdfViewerButton
            key={index}
            pdfSrc={pdfSrc}
            buttonText={`Descargar ${index + 1}`}
            handleDownloadClick={() => handleDownloadClick(pdfSrc)}
          />
        ))}
      </Grid>
    );
  };
  return (
    <div>
      <h1>Reportes Docentes</h1>
      <Grid
        container
        spacing={2}
        style={{ alignItems: 'center', marginTop: '10px' }}
      >
        <YearButton year={2022} onClick={() => handleYearButtonClick(2022)} />
        <YearButton year={2023} onClick={() => handleYearButtonClick(2023)} />
      </Grid>
      {selectedYear && (
        <Grid
          container
          spacing={2}
          style={{ alignItems: 'center', marginTop: '0px' }}
        >
          {selectedYear === 2022 && renderPdfViewerButtons(pdfSrcs2022)}
          {selectedYear === 2023 && renderPdfViewerButtons(pdfSrcs2023)}
        </Grid>
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};
export default connect(mapStateToProps)(EncuestasCoord);
