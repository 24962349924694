import { makeStyles } from '@mui/styles';

export default makeStyles({
  title: {
    margin: '2%',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '28px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  paperContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '250px',
    borderRadius: '12px',
    wordWrap: 'break-word',
  },
  containerData: {
    alignContent: 'center',
    flexDirection: 'column',
    width: '80%',
    margin: '10%',
  },
  textContainer: {
    position: 'relative',
    height: '200px',
  },
  centerContainer: {
    margin: '0',
    position: 'absolute',
    top: '5%',
  },
  paragraphContainer: {
    margin: '0',
  },
  titlePaper: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '16px',
    marginBottom: '14px',
  },
  dataTitle: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 'normal',
  },
});
