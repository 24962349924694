/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'utils/axios';
import { getToken } from 'services/user/user.service';
import { IFilter } from 'services/info/info.interface';

type CardData = {
  title: string;
  total: string;
  description: string;
};
/**
 * Service for [[Cards]
 */
const CardsService = {
  /**
   * @param token user's token
   * @returns graph data ready to render
   */
  getNumeroGraduadosAcumulado: async (
    filters: IFilter[],
  ): Promise<CardData> => {
    const token = getToken();
    const queryString = `?access_token=${token}`;

    const response = await axios.post(
      `/v1.1/uruguay_global/numero_graduados_acumulado${queryString}`,
      filters,
    );
    return response.data as CardData;
  },
};

export default CardsService;
