import { makeStyles } from '@mui/styles';

export default makeStyles({
  paperContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '24px',
    margin: '15px',
  },
  title: {
    margin: '24px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '28px',
    letterSpacing: '0px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    gap: '0 10px',
  },
  titlePaper: {
    fontWeight: 'bold',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: '#F2F8FF',
    padding: '8px',
    borderRadius: '8px',
  },
  iconColor: {
    color: '#007BFF',
  },
  containerIconAndData: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
  },
  dataTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  headerRow: {
    background: '#EAEEF2',
    maxWidth: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '12px 12px 0 0 ',
  },
  headerText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#777D92',
    margin: '2%',
  },
  headerIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '2%',
  },
  colorAprovedd: {
    color: '#D5E8C2',
  },
  colorDisapproved: {
    color: '#FDBFBF',
  },
  /*table css */
  table: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    overflow: 'auto',
    // minHeight: '50vh',
    maxHeight: '320px',
    '&::-webkit-scrollbar': {
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#DCDCDC',
      borderRadius: '40px',
    },
  },
  tableItemContainer: {
    padding: '15px',
    borderRight: '1px solid #DFE0EB',
  },
  tableItemHeader: {
    fontWeight: 'bold',
    margin: '5px',
  },
  chipContainer: {
    margin: '5px',
  },
  chipItems: {
    fontSize: '10',
    width: '45',
    height: '15',
    borderRadius: '25px',
  },

  statusText: {
    padding: '8px',
    textTransform: 'capitalize',
    fontWeight: 'normal',
    fontSize: '16px',
    borderRadius: '8px',
    width: 'fit-content',
    color: '#fff',
  },

  statusActivo: {
    backgroundColor: '#007BFF',
  },

  statusSuspendido: {
    backgroundColor: '#FFA500',
  },
});
