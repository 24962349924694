import { makeStyles } from '@mui/styles';

export default makeStyles({
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  containerHeaderChildren: {
    width: '75%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerChildrenIcon: {
    margin: '10px',
    color: 'blue',
    background: '#007BFF0D',
  },
  iconColor: {
    color: '#007BFF !important',
  },
  headerChildren: {
    margin: '10px',
  },
  loading: {
    display: ' flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  paperNoData: {
    display: ' flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    marginTop: '5%',
    color: '#82858E',
  },
});
