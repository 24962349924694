import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button } from '@material-ui/core';
import useLists from 'utils/customHooks/useLists';
import { makeStyles } from '@material-ui/core/styles';

interface EncuestasCListadoProps {
  filters: any;
}

interface IListResponse {
  [key: string]: any;
}

const useStyles = makeStyles({
  table: {
    width: '100%',
    tableLayout: 'auto',
    '@media (max-width: 600px)': {
      fontSize: '10px',
    },
  },
  rowEven: {
    backgroundColor: '#f5f5f5',
  },
  rowOdd: {
    backgroundColor: '#ffffff',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'normal',
    fontSize: '10px',
    padding: '8px',
    textAlign: 'center',
  },
  numericCell: {
    textAlign: 'center', // Centramos horizontalmente
    verticalAlign: 'middle', // Centramos verticalmente
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'normal',
    fontSize: '10px',
    padding: '8px',
  },
  commentCell: {
    whiteSpace: 'pre-line',
    lineHeight: 1.8,
    fontSize: '10px',
    fontWeight: 'normal',
    padding: '8px',
    textAlign: 'left',
  },
  nameCell: {
    fontSize: '10px',
    textAlign: 'center',
  },
  tableContainer: {
    maxHeight: 'none', // Elimina el límite de altura para la tabla de "Encuestas Docentes"
    overflow: 'visible', // Asegura que no haya scroll
    minHeight: '400px',
  },
  docenteCell: {
    whiteSpace: 'normal', // Permite saltos de línea
    wordWrap: 'break-word', // Divide palabras largas si es necesario
    fontSize: '9px',
    padding: '8px',
  },
  cursoCell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflow: 'visible', 
    fontSize: '9px',
    padding: '8px',
  
  },
  tableHeader: {
    fontSize: '9px',
    whiteSpace: 'normal',
    textAlign: 'center',
    backgroundColor: '#f2f2f2',
  },
  sectionTitle: {
    marginTop: '20px',
    marginBottom: '10px',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  notes: {
    fontSize: '12px',
    fontStyle: 'italic',
    marginTop: '10px',
  },
});


const EncuestasCListado: React.FC<EncuestasCListadoProps> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  // Fetch data for UC
  const { users: answersResults1S, isLoadingList: loading1S } = useLists('/v1.1/encuestascoordinador/listado1S', props.filters);
  const { users: answersResults2S, isLoadingList: loading2S } = useLists('/v1.1/encuestascoordinador/listado2S', props.filters);
  const { users: comentariosDocentes1S, isLoadingList: loadingComentarios1S } = useLists('/v1.1/encuestascoordinador/comentariosDocentes1S', props.filters);
  const { users: comentariosDocentes2S, isLoadingList: loadingComentarios2S } = useLists('/v1.1/encuestascoordinador/comentariosDocentes2S', props.filters);

  // Controlar el estado de carga
  useEffect(() => {
    if (loading1S || loading2S || loadingComentarios1S || loadingComentarios2S) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loading1S, loading2S, loadingComentarios1S, loadingComentarios2S]);

  const headers = [
    'ID',
    'Nombre Docente',
    'Curso',
    'Las instancias de intercambio (clases) están bien preparadas y organizadas',
    'Desarrolla los temas de forma clara y entendible',
    'Promueve la participacion y reflexion de sus estudiantes',
    'Usa ejemplos y casos relacionados con la vida real',
    'Tiene un trato cordial y amable',
    'Utiliza recursos variados como apoyo didáctico',
    'Realiza instancias de correcion para evacuar dudas y  que el estudiante..',
    'Es accesible y esta dispuesto a ayudarme cuando lo solicito',
    'Cumple adecuadamente con la planificación semanal del curso (horario)',
    'Cuán satisfecho/a estas con la actuación del docente',
    'Total de estudiantes que respondieron.',
    'Total de estudiantes inscriptos a la UC.',
  ];

  const commentHeaders = ['Nombre Docente', 'Comentario'];

  const transformData = (data: IListResponse[], headers: string[], includeHeaders: boolean = true): any[][] => {
    if (data.length === 0) return [];

    const rows = data.map(item => {
      const nombreDocente = item[0] ? String(item[0]).trim() : '';
      const curso = item[1] ? String(item[1]).trim() : '';
      const promedios = item.slice(2);

      const paddedPromedios = promedios.length < headers.length - 2
        ? promedios.concat(Array(headers.length - 2 - promedios.length).fill(''))
        : promedios.slice(0, headers.length - 2);

      return [nombreDocente, curso, ...paddedPromedios];
    });

    const filteredRows = rows.filter(row => row[0] !== 'id');

    return includeHeaders ? [headers, ...filteredRows] : filteredRows;
  };

  const cleanDuplicateNames = (name: string): string => {
    const nameLength = name.length;
    for (let i = 1; i <= nameLength / 2; i++) {
      const firstPart = name.slice(0, i);
      const secondPart = name.slice(i, i + firstPart.length);
      if (firstPart === secondPart) {
        return firstPart;
      }
    }
    return name;
  };

  const transformComments = (data: IListResponse[]): any[][] => {
    if (data.length === 0) return [];

    const groupedComments: { [key: string]: string[] } = {};

    data.forEach(item => {
      const nombreDocente = item[0] || '';
      const comentario = item[1] || '';
      const cleanedNombreDocente = cleanDuplicateNames(nombreDocente);

      if (!groupedComments[cleanedNombreDocente]) {
        groupedComments[cleanedNombreDocente] = [];
      }
      groupedComments[cleanedNombreDocente].push(`• ${comentario}`);
    });

    const rows = Object.keys(groupedComments).map(nombreDocente => {
      const formattedComments = groupedComments[nombreDocente]
        .map(comment => comment.replace(/\n/g, '\n• '))
        .join('\n');
      return [nombreDocente, formattedComments];
    });

    rows.sort((a, b) => {
      const nameA = a[0].toLowerCase();
      const nameB = b[0].toLowerCase();
      return nameA.localeCompare(nameB, 'es', { sensitivity: 'base' });
    });

    return [commentHeaders, ...rows];
  };

  const transformedAnswersResults1S = transformData(answersResults1S, headers);
  const transformedAnswersResults2S = transformData(answersResults2S, headers);
  const transformedComentariosDocentes1S = transformComments(comentariosDocentes1S);
  const transformedComentariosDocentes2S = transformComments(comentariosDocentes2S);

  const sortByName = (data: any[][]) => {
    return data.sort((a, b) => {
      const nameA = a[1] ? a[1].toString().normalize("NFD").replace(/[̀-ͯ]/g, "").trim().toLowerCase() : '';
      const nameB = b[1] ? b[1].toString().normalize("NFD").replace(/[̀-ͯ]/g, "").trim().toLowerCase() : '';
      return nameA.localeCompare(nameB, 'es', { sensitivity: 'base' });
    });
  };

  const sortedAnswers1S = sortByName(transformedAnswersResults1S.slice(1));
  const sortedAnswers2S = sortByName(transformedAnswersResults2S.slice(1));
  const sortedComments1S = sortByName(transformedComentariosDocentes1S.slice(1));
  const sortedComments2S = sortByName(transformedComentariosDocentes2S.slice(1));

  const convertToCSV = (data: any[][]): string => {
    return data.map(row => row.join(',')).join('\n');
  };

  const downloadCSV = (data: any[][], filename: string) => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleExportAnswers1S = () => {
    downloadCSV(transformedAnswersResults1S, 'encuestas_docentes_1S.csv');
  };

  const handleExportComments1S = () => {
    downloadCSV(transformedComentariosDocentes1S, 'comentarios_docentes_1S.csv');
  };

  const handleExportAnswers2S = () => {
    downloadCSV(transformedAnswersResults2S, 'encuestas_docentes_2S.csv');
  };

  const handleExportComments2S = () => {
    downloadCSV(transformedComentariosDocentes2S, 'comentarios_docentes_2S.csv');
  };

  const renderTable = (data: any[][], headers?: string[], hideFirstRows: number = 0, hideID: boolean = false, isEncuestasDocentes: boolean = false) => {
    const filteredData = data.filter(row => {
      return !(row[0] === 'docente' && row[1] && row[1].includes('respuestas'));
    });
  
    return (
      <TableContainer key={`${transformedAnswersResults1S.length}-${transformedComentariosDocentes1S.length}-${transformedAnswersResults2S.length}-${transformedComentariosDocentes2S.length}`} component={Paper} className={isEncuestasDocentes ? classes.tableContainer : undefined}>
        <Table className={classes.table}>
          {headers && (
            <TableHead>
              <TableRow>
                {headers.map((header: string, index: number) => {
                  if (hideID && index === 0) return null;
                  return (
                    <TableCell key={index} className={classes.tableHeader} style={{ width: 'auto' }}>
                      {header}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {filteredData.slice(hideFirstRows).map((row: any[], rowIndex: number) => (
              <TableRow
                key={rowIndex}
                className={rowIndex % 2 === 0 ? classes.rowEven : classes.rowOdd}
              >
                {row.map((cell: any, cellIndex: number) => {
                  if (hideID && cellIndex === 0) return null;
  
                  let cellStyle = classes.tableCell;
  
                  // Aplicamos el estilo de celda de docente y curso para Encuestas Docentes
                  if (isEncuestasDocentes) {
                    if (cellIndex === 0) cellStyle = classes.docenteCell;
                    if (cellIndex === 1) cellStyle = classes.cursoCell;
                  }
  
                  // Aplicamos el estilo de celdas numéricas si el valor es un número
                  if (!isNaN(cell) && typeof cell === 'number') {
                    cellStyle = classes.numericCell;
                  }
  
                  if (headers === commentHeaders) {
                    cellStyle = classes.commentCell;
                  }
  
                  return (
                    <TableCell key={cellIndex} className={cellStyle}>
                      {cell}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  

  return (
    <Grid container spacing={2} style={{ marginTop: '0px' }}>
      <Grid item xs={12}>
        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: '10px' }}></Typography>
          </div>
        ) : (
          <>
            <Typography variant="h6">Reportes Coordinación Docentes</Typography>
            <Typography variant="h6">Primer Semestre</Typography>
            {!loading && transformedAnswersResults1S.length > 0 ? (
              <>
                <Typography variant="h6">Encuestas Docentes 1S:</Typography>
                {renderTable([headers, ...sortedAnswers1S], headers, 2, true, true)}
                <Typography className={classes.notes}>
                  * El promedio refiere a la frecuencia con que se desarrollan los items consultados, donde 1 es Nunca y 4 es Siempre.
                </Typography>
                <Typography className={classes.notes}>
                  ** No se incluye en el promedio las respuestas "No tengo elementos para evaluarlo" y "No sabe no contesta".
                </Typography>
                <Typography className={classes.notes}>
                  Las UCs con menos de cuatro respuestas no se evalúan para mantener el anonimato de los estudiantes.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleExportAnswers1S}>Exportar Encuestas Docentes 1S</Button>
              </>
            ) : (
              <Typography variant="body1">No hay datos para el periodo 1S.</Typography>
            )}

            {!loading &&  transformedComentariosDocentes1S.length > 0 ? (
              <>
                <Typography variant="h6" style={{ marginTop: '20px' }}>Comentarios Docentes 1S</Typography>
                {renderTable([commentHeaders, ...sortedComments1S], commentHeaders, 2)}
                <Button variant="contained" color="primary" onClick={handleExportComments1S} style={{ marginLeft: '10px' }}>Exportar Comentarios Docentes 1S</Button>
              </>
            ) : (
              <Typography variant="body1">No hay comentarios para los docentes en 1S.</Typography>
            )}

            <Typography variant="h6" style={{ marginTop: '20px' }}>Segundo Semestre</Typography>
            {!loading && transformedAnswersResults2S.length > 0 ? (
              <>
                <Typography variant="h6">Encuestas Docentes 2S:</Typography>
                {renderTable([headers, ...sortedAnswers2S], headers, 2, true, true)}
                <Typography className={classes.notes}>
                  * El promedio refiere a la frecuencia con que se desarrollan los items consultados, donde 1 es Nunca y 4 es Siempre.
                </Typography>
                <Typography className={classes.notes}>
                  ** No se incluye en el promedio las respuestas "No tengo elementos para evaluarlo" y "No sabe no contesta".
                </Typography>
                <Typography className={classes.notes}>
                  Las UCs con menos de cuatro respuestas no se evalúan para mantener el anonimato de los estudiantes.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleExportAnswers2S} style={{ marginTop: '10px' }}>Exportar Encuestas Docentes 2S</Button>
              </>
            ) : (
              <Typography variant="body1">No hay datos para el periodo 2S.</Typography>
            )}

            {!loading && transformedComentariosDocentes2S.length > 0 ? (
              <>
                <Typography variant="h6" style={{ marginTop: '20px' }}>Comentarios Docentes 2S</Typography>
                {renderTable([commentHeaders, ...sortedComments2S], commentHeaders, 2)}
                <Button variant="contained" color="primary" onClick={handleExportComments2S} style={{ marginLeft: '10px', marginTop: '10px' }}>Exportar Comentarios Docentes 2S</Button>
              </>
            ) : (
              <Typography variant="body1">No hay comentarios para los docentes en 2S.</Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  filters: state.filters,
});

export default connect(mapStateToProps)(EncuestasCListado);
