import React from 'react';

import useStyles from './ViewTitle.styles';
import { ITitle } from './ViewTitle.interface';

/**
 * Component to set Titles on the different views
 * 
 * @component
 */
const ViewTitle = ({title}: ITitle) => {
  const classes = useStyles();

  return (
    <>
      <h2 className={classes.title}>{title}</h2>
    </>
  );
};

export default ViewTitle;
