import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';

import HeaderProfile from 'components/userProfileInfo/headerProfile/HeaderProfile';
import { IUserProfile } from '../UserProfile.interface';
import useStyles from '../UserProfile.styles';
import UserProfileService from 'services/userProfile/userProfile.service';

/**
 * Applicants Profile displaying basic data with [[HeaderProfile]] component
 *
 * @component PerfilPostulante
 */
const PerfilPostulante = (props: IUserProfile) => {
  const style = useStyles();
  const search = props.location.search;
  const id = new URLSearchParams(search).get('id') || '0';

  const empyInfo: any = [];
  const [userData, setUserData] = useState(empyInfo);
  
  useEffect(() => {
    async function fetchUserData() {
      const result: any = await UserProfileService.getApplicantData(
        id,
        props.filters,
      );

      setUserData(result);
    }

    fetchUserData();
  }, []);

  if (userData.length === 0) {
    return (
      <Grid item xs={12} className={style.loading}>
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12}>
          <HeaderProfile data={userData.headerProfile} />
        </Grid>
      </Grid>
    );
  }
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(PerfilPostulante);
