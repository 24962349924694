import React from 'react';

import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import useStyles from './ActivityAtEdu.style';
import PaperBoxWithIcon from 'components/rootComponents/Standards/PaperBoxWithIcon/PaperBoxWithIcon';
import ViewTitle from 'components/rootComponents/Standards/ViewTitle/ViewTitle';
import { IActivityEduData } from './ActivityEdu.interface';
import ActivityAtEduBox from './activityAtEduBox/ActivityAtEduBox';

/**
 * Component used to render an Activity at Edu for the [[StudentProfile]] view
 *
 * @component
 */
const ActivityAtEdu = ({ data }: IActivityEduData) => {
  const style = useStyles();
  const eduData = [
    {
      title: 'Cursos inscriptos',
      quantity: data?.cursos_inscriptos.quantity,
      icon: <LocalLibraryIcon sx={{ color: '#007BFF' }} />,
      data: '',
    },
    {
      title: 'Cursos finalizados',
      quantity: data?.cursos_finalizados.quantity,
      icon: <AutoStoriesIcon sx={{ color: '#007BFF' }} />,
      data: '',
    },
    {
      title: 'Ultimos cursos inscriptos',
      quantity: data?.ultimos_cursos.quantity,
      icon: (
        <MenuBookIcon
          sx={{
            color: '#007BFF',
          }}
        />
      ),
      data: data?.ultimos_cursos.data,
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={style.title}>
          <ViewTitle title={'Actividad en EDU'} />
        </Box>
      </Grid>
      {eduData &&
        eduData.map((activity, index) => {
          if (activity?.data?.length > 0) {
            return (
              <Grid item xs={12} md={4} key={activity.title}>
                <ActivityAtEduBox
                  title={activity.title}
                  icon={activity.icon}
                  quantity={activity.quantity}
                  data={activity.data}
                />
              </Grid>
            );
          }
          return (
            <Grid item xs={12} md={4} key={index}>
              <PaperBoxWithIcon
                title={activity.title}
                data={activity.quantity}
                icon={activity.icon}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ActivityAtEdu;
