import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    marginTop: '28px',
  },

  colorTitle: {
    color: '#1976d2',
    fontSize: '1.5rem',
  },
  colorSubtitle: {
    color: '#009bdb',
  },
  colorBody: {
    color: 'GrayText',
  },
  iconWhatssap: {
    color: 'green',
    marginRight: '2px',
  },
  iconEmail: {
    color: '#1976d2',
    marginRight: '2px',
  },

  containerInfoContact: {
    marginTop: '20px',
  },
  infoContact: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2px',
  },
});

export default useStyles;
