import React from 'react';

import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

import useStyles from './PaperBox.style';
import { IPaperBox } from './PaperBox.interface';

/**
 * Component to set PaperBox on the different views
 *
 * @component
 */
const PaperBox = ({ title, data }: IPaperBox) => {
  const style = useStyles();
  return (
    <Paper className={style.paperContainer}>
      <Box className={style.containerData}>
        <Typography className={style.titlePaper}>{title}</Typography>
        <div className={style.textContainer}>
          <div className={style.centerContainer}>
            <p className={style.paragraphContainer}>
              {data &&
                data.map((data, index) => {
                  return (
                    <Typography key={index} className={style.dataTitle}>
                      <strong>{data.label}</strong>: {data.value}
                    </Typography>
                  );
                })}
            </p>
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default PaperBox;
