import { makeStyles } from '@mui/styles';

export default makeStyles({
  submitButton: {
    width: '100%',
    background: '#007bff',
    color: 'white',
    fontSize: '20px',
    lineHeight: '48px',
    padding: '0px 16 px',
    marginBottom: '10px',

    '&:hover': {
      backgroundColor: '#1565c0',
    }
  },
  forgottenPassword: {
    color: '#007bff',
    cursor: 'pointer',
  },
});
