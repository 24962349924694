import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import List from 'components/list/List';
import { IHeaders } from 'components/list/ListHeaders/ListHeaders.interface';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import SearchInput from 'components/rootComponents/Standards/SearchInput/SearchInput';
import useLists from 'utils/customHooks/useLists';
import { IEstudiantes } from '../Estudiantes.interface';
import { generateAndDownloadCsvContentList } from 'utils/helper';

/**
 *
 * Estudiantes view to display different [[Chart]] and [[List]]
 *
 * @component
 */
const EstudiantesUGListado = (props: IEstudiantes) => {
  const title = 'Estudiantes';
  const searchPlaceholder = 'Buscar estudiante...';

  const {
    users,
    columns,
    isLoadingList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    search,
    setSearch,
    sortDirection,
    setSortDirection,
    totalPages,
  } = useLists('/v1.1/estudiantesUG/listado', props.filters);

  console.log("Datos de users:", users);
  console.log("Columnas:", columns);

  const headerCells: IHeaders[] = [
    {
      id: 'name',
      numeric: false,
      label: 'Nombre',
    },
  ];

  users[0]?.valores.forEach((valor: any, index: number) => {
    const isNumeric = valor.column === 'fecha_nacimiento' || valor.column === 'generacion';

    if (valor.column !== 'probabilidad_abandono' && valor.column !== 'itr') {
      const formattedHeader: IHeaders = {
        id: valor.column,
        label: columns[index],
        numeric: isNumeric,
      };
      headerCells.push(formattedHeader);
    }
  });

  const studentsList = users.map((student: any) => {
    let formattedStudent = {
      name: student.nombre,
      // Cambia el enlace para que use "/estudiantes/" en lugar de "/estudiantesUG/"
      link: student.__link.replace('/estudiantesUG/', '/estudiantes/'),
    };

    for (let i = 0; i < student.valores.length; i++) {
      let column = student.valores[i].column;
      let value = student.valores[i].valor;

      if (column === 'residencia_montevideo') {
        value = value === 'Si' ? 'No' : value === 'No' ? 'Si' : value;
      }
      if (column === "discapacidad") {
        value = 'No';
      }

      if (column === "beca") {
        value = value === 'S' ? 'Si' : 'No';
      }

      if (column !== 'probabilidad_abandono' && column !== 'itr') {
        formattedStudent = {
          ...formattedStudent,
          [column]: value,
        };
      }
    }

    return formattedStudent;
  });

  // Reemplazar "UG" en la barra de direcciones
  useEffect(() => {
    const currentUrl = window.location.href;
    const updatedUrl = currentUrl.replace('/estudiantesUG/', '/estudiantes/');
    if (currentUrl !== updatedUrl) {
      window.history.replaceState(null, '', updatedUrl);
    }
  }, []);

  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        listUrl={'/v1.1/navegacion/estudiantesUG/listado'}
      />
      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <IconButton
          onClick={() =>
            generateAndDownloadCsvContentList(headerCells, studentsList, title)
          }
        >
          <DownloadIcon sx={{ color: '#007BFF' }} />
        </IconButton>
      </Grid>
      <Grid item xs={10} style={{ textAlign: 'right' }}>
        <SearchInput
          searchPlaceholder={searchPlaceholder}
          setSearchBar={setSearch}
        />
      </Grid>
      <Grid item xs={12}>
        <List
          search={search}
          headers={headerCells}
          rows={studentsList}
          isLoading={isLoadingList}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPages={totalPages}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(EstudiantesUGListado);
