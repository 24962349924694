import { useEffect, useRef, useState } from 'react';
import { IChart } from 'components/charts/Charts.interface';
import ChartService from 'services/chart/chart.service';

/**
 * This hook will show a [[Spinner]] component to represent that information is being retrieved. Once the data is retrieved, [[Chart]] components will start rendering
 * @param url Url to retrieve the list of [[Chart]] per view
 * @param filters Filters stored in Redux used as http request body
 * @returns List of charts to be rendered on each view
 */
export default function useCharts(url: string, filters: any, id: string = '') {
  const [title, setTitle] = useState('');
  const firstUpdate = useRef(true);

  // charts
  const emptyGraph: IChart[] = [];
  const [charts, setCharts] = useState(emptyGraph);
  // only used while the first API call lets us know how many charts we are gonna render
  const [isLoadingCharts, setIsLoadingCharts] = useState(true);

  // Create a ref to track if the component is still mounted
  const isMounted = useRef(true);

  async function getChartsLinks(abortController: any) {
    return await ChartService.getChartsLinks(url, filters, id, abortController);
  }

  // retrieve charts
  useEffect(() => {
    const abortController = new AbortController();

    // Mark the component as mounted
    isMounted.current = true;

    setIsLoadingCharts(true);

    async function fetchGraphs() {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }

      if (filters?.length === undefined || !filters) {
        return;
      }

      const { links, metaTitle } = await getChartsLinks(abortController.signal);

      if (isMounted.current) {
        setTitle(metaTitle);
        setIsLoadingCharts(false);
      }

      // start showing charts as loading
      const parsedCharts =
        links?.map((chart: any) => {
          return { url: chart.__link, isLoading: true };
        }) ?? {};

      if (isMounted.current) {
        setCharts(parsedCharts);
        updateCharts(parsedCharts);
      }
    }

    fetchGraphs();

    return () => {
      // Cleanup on component unmount
      isMounted.current = false;
      abortController.abort();
    };
  }, [filters]);

  const updateCharts = (parsedCharts: IChart[]) => {
    // retrieve info for each chart
    parsedCharts?.map?.(async (chartURL: IChart, index: number) => {
      if (chartURL.url && isMounted.current) {
        const graphData = await ChartService.getChart(chartURL.url, filters);

        if (isMounted.current) {
          setCharts((prev) => {
            const tempArray = [...prev];
            tempArray[index] = { ...graphData };
            return [...tempArray];
          });
        }
      }
    });
  };

  return { isLoadingCharts, charts, title };
}
