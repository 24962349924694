import { makeStyles } from '@mui/styles';

export default makeStyles({
  search: {
    width: '50%',
    border: '1px solid #DFE0EB',
    padding: '10px 20px',
    background: '#F9FAFB',
    borderRadius: '12px',
  },
});
