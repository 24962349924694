import React from 'react';

import { Chip } from '@mui/material';
import { Box } from '@mui/system';

import { ITag } from './Tag.interface';

/**
 * Component to set Tag on the different views
 *
 * @component
 */
const Tag = ({ label, color, bg }: ITag) => {
  return (
    <Box title={label}>
      <Chip
        label={label}
        sx={{
          backgroundColor: `${bg}`,
          color: `${color}`,
          borderRadius: '8px',
          marginRight: '5px',
          maxWidth: '15vw',
          wordWrap: 'break-word',
          cursor: 'pointer',
        }}
      />
    </Box>
  );
};

export default Tag;
