import React from 'react';

import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';

import HeaderProfile from 'components/userProfileInfo/headerProfile/HeaderProfile';
import UserService from 'services/user/user.service';
import useStyles from './Profile.styles';

/**
 * Show the users profile view
 * @component Profile
 */
const Profile = () => {
  const style = useStyles();
  const user = UserService.getSessionUser();

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderProfile data={user} />
      </Grid>

      <Grid item mt={2} xs={12} md={6}>
        <Paper className={style.containerHeader}>
          <Box>
            <Typography className={style.titleHeader} variant={'h6'}>
              Sobre Aprendizaje Aumentado
            </Typography>
            <Box className={style.containerHeaderSubtitule}>
              <Typography>
                Desarrollamos la primera versión de Aprendizaje Aumentado con el
                objetivo de analizar el ciclo de vida académico de los
                estudiantes de la Universidad y obtener información detallada de
                los procesos de enseñanza y aprendizaje. Esta solución nuclea
                los datos de las soluciones digitales utilizadas por UTEC y
                permite visualizar la información en una interfaz simple e
                intuitiva.
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>

      <Grid item mt={2} xs={12} md={6}>
        <Paper className={style.containerHeader}>
          <Box>
            <Typography className={style.titleHeader} variant={'h6'}>
              ¡Ayúdanos a seguir mejorando!
            </Typography>
            <Box className={style.containerHeaderSubtitule}>
              <Typography>
              Completá este breve formulario y comentanos sobre tu experiencia al navegar por la aplicación, todos los comentarios que realices serán aportes para continuar construyendo y mejorando esta solución.
              </Typography>
            </Box>
            <Box mt={5}>
              <a
                href="https://forms.gle/HHvPNKZ5xgDDoo6R8"
                rel="noreferrer"
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Button
                  size="small"
                  variant="contained"
                  className={style.button}
                  startIcon={<AssignmentIcon />}
                >
                  Completar
                </Button>
              </a>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
