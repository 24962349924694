import React from 'react';
import { useHistory } from 'react-router-dom';

import { Grid } from '@mui/material';
import { BarChart, FormatListBulleted } from '@mui/icons-material';
import ViewStreamIcon from '@mui/icons-material/ViewStream';

import ViewTitle from 'components/rootComponents/Standards/ViewTitle/ViewTitle';
import { IViewHeader } from './ViewHeader.interface';

/**
 * Component to set Titles on the different views
 *
 * @component
 */
const ViewHeader = ({ title, chartUrl, asignaturesUrl, listUrl }: IViewHeader) => {
  const history = useHistory();

  return (
    <>
      <Grid item xs={9}>
        <ViewTitle title={title} />
      </Grid>
      <Grid item xs={3} style={{ textAlign: 'right' }}>
        {chartUrl && (
          <BarChart
            onClick={() => history.push(chartUrl)}
            style={{ cursor: 'pointer', margin: '0 5px' }}
          />
        )}
        {asignaturesUrl && (
          <ViewStreamIcon
            onClick={() => history.push(asignaturesUrl)}
            style={{ cursor: 'pointer', margin: '0 5px' }}
          />
        )}
        {listUrl && (
          <FormatListBulleted
            onClick={() => history.push(listUrl)}
            style={{ cursor: 'pointer', margin: '0 5px' }}
          />
        )}
      </Grid>
    </>
  );
};

export default ViewHeader;
