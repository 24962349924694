import { makeStyles } from '@mui/styles';

export default makeStyles({
  tableItemContainer: {
    padding: '15px',
    borderRight: '1px solid #DFE0EB',
  },
  tableItemHeader: {
    fontWeight: 'bold',
    margin: '5px',
  },
  chipContainer: {
    margin: '5px',
  },
  chipItems: {
    fontSize: '10',
    width: '45',
    height: '15',
    borderRadius: '25px',
  },
});
