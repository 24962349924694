import React, { useState } from 'react';

import { ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';

import Tag from 'components/rootComponents/Standards/Tag/Tag';
import { IActivityTableBody } from './ActivityTableBody.interface';
import IconTag from 'components/rootComponents/Standards/iconTag/IconTag';
import useStyles from './ActivityTableBody.style';

/**
 * this component is used to render the table with subjects sorted by semester and year
 *
 * @component
 */
const ActivityTableBody = ({ data }: IActivityTableBody) => {
  const style = useStyles();

  const [activeTable, setOpenTable] = useState<string>('S1');

  const handleChangeTable = (selectedTable: string) => {
    setOpenTable(selectedTable);
  };

  return (
    <Box>
      <Box className={style.tableItemContainer}>
        <Box className={style.tableItemHeader}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: '19px',
              marginBottom: '8px',
            }}
          >
            {data.year}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box onClick={() => handleChangeTable('S1')}>
              {activeTable === 'S1' ? (
                <IconTag
                  label={'S1'}
                  icon={<CheckIcon sx={{ fontSize: 10 }} />}
                  color="#007BFF"
                  bg="#007BFF0D"
                />
              ) : (
                <Tag label="S1" color="" bg="#e3e4f0" />
              )}
            </Box>
            <Box onClick={() => handleChangeTable('S2')}>
              {activeTable === 'S2' ? (
                <IconTag
                  label={'S2'}
                  icon={<CheckIcon sx={{ fontSize: 10 }} />}
                  color="#007BFF"
                  bg="#007BFF0D"
                />
              ) : (
                <Tag label="S2" color="" bg="#e3e4f0" />
              )}
            </Box>
            <Box onClick={() => handleChangeTable('AN')}>
              {activeTable === 'AN' ? (
                <IconTag
                  label={'Anual'}
                  icon={<CheckIcon sx={{ fontSize: 10 }} />}
                  color="#007BFF"
                  bg="#007BFF0D"
                />
              ) : (
                <Tag label="Anual" color="" bg="#e3e4f0" />
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            listStyle: 'none',
            p: 0.5,
            m: 0,
          }}
        >
          {activeTable === 'S1' &&
            data.data.S1.map((data: any, index: any) => {
              if (data.aproved === true) {
                return (
                  <ListItem key={index} sx={{ paddingLeft: '0px' }}>
                    <Tag label={data.label} bg={'#D5E8C2'} color="" />
                  </ListItem>
                );
              }
              return (
                <ListItem key={index} sx={{ paddingLeft: '0px' }}>
                  <Tag label={data.label} bg="#FDBFBF" color="" />
                </ListItem>
              );
            })}

          {activeTable === 'S2' &&
            data.data.S2.map((data: any, index: any) => {
              if (data.aproved === true) {
                return (
                  <ListItem key={index} sx={{ paddingLeft: '0px' }}>
                    <Tag label={data.label} bg={'#D5E8C2'} color="" />
                  </ListItem>
                );
              }
              return (
                <ListItem key={index} sx={{ paddingLeft: '0px' }}>
                  <Tag label={data.label} bg="#FDBFBF" color="" />
                </ListItem>
              );
            })}

          {activeTable === 'AN' &&
            data.data.AN.map((data: any, index: any) => {
              if (data.aproved === true) {
                return (
                  <ListItem key={index} sx={{ paddingLeft: '0px' }}>
                    <Tag label={data.label} bg={'#D5E8C2'} color="" />
                  </ListItem>
                );
              }
              return (
                <ListItem key={index} sx={{ paddingLeft: '0px' }}>
                  <Tag label={data.label} bg="#FDBFBF" color="" />
                </ListItem>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityTableBody;
