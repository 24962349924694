import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import Chart from 'components/charts/Charts';
import useCharts from 'utils/customHooks/useCharts';
import { IUruguayGlobal } from '../UruguayGlobal.interface';
import CardsService from 'services/cards/cards.service';
import useStyles from '../../../components/charts/Charts.styles';

type CardData = {
  title: string;
  total: string;
  description: string;
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
/**
 *
 * UruguayGlobal view to display different [[Chart]] and [[List]]
 *
 * @component
 */
const UruguayGlobalGraficas = (props: IUruguayGlobal) => {
  //Hooks
  const { isLoadingCharts, charts } = useCharts(
    window.location.pathname,
    props.filters,
  );
  const classes = useStyles();

  const [isLoadingCard, setIsLoadingCard] = useState<boolean>(false);
  const [cardData, setCardData] = useState<CardData | null>(null);
  const title = 'Uruguay Global';

  useEffect(() => {
    setIsLoadingCard(true);
    async function getCard() {
      if (!props.filters.length) return;
      const data = await CardsService.getNumeroGraduadosAcumulado(
        props.filters,
      );
      setCardData(data);
      setIsLoadingCard(false);
    }
    getCard();
  }, [props.filters]);
  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/uruguay_global/graficas'}
        listUrl={'/v1.1/navegacion/uruguay_global/listado'}
      />

      {isLoadingCharts && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      )}

      {!isLoadingCharts && (
        <Grid item key={0} xs={12}>
          {/* <Item className={classes.chartGridBox}> */}
          <Grid item xs={12} md={8} style={{ gap: 5, display: 'flex' }}>
            <p className={classes.chartTitle}>{cardData?.title}: </p>
            <p className={classes.chartTitle}>
              {cardData?.total ? parseInt(cardData.total) : '-'}
            </p>
          </Grid>
          {/* </Item> */}
        </Grid>
      )}

      {!isLoadingCharts &&
        charts.map((chart, index) => {
          return (
            <Grid item key={index + 1} xs={12}>
              <Chart {...chart} />
            </Grid>
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(UruguayGlobalGraficas);
