import { makeStyles } from '@mui/styles';

export default makeStyles({
  containerHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px',
    borderRadius: '12px',
  },
  containerHeaderChildren: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  containerHeaderSubtitule: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatarContainer: {
    margin: '2%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  titleHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  headerChildrenIcon: {
    margin: '10px',
    color: 'blue',
    background: '#007BFF0D',
  },
  iconColor: {
    color: '#007BFF !important',
  },
  headerChildren: {
    margin: '10px',
  },
});
