import { makeStyles } from '@mui/styles';

export default makeStyles({
  selectedYearContainer: {
    height: '40px',
    background: '#ffffff',
    border: '0.5px solid #dfe0eb',
    boxSizing: 'border-box',
    borderRadius: '12px',
    padding: '13px',
    marginBottom: '2px',
    cursor: 'pointer',
  },
  selectedYear: {
    position: 'relative',
    marginLeft: '10px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '14px',
    color: '#31374c',
  },
  yearExpansionPanel: {
    background: '#ffffff',
    border: '0.5px solid #dfe0eb',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '12px !important',
    padding: '5px',
  },
  buttonSelectYear: {
    border: 'none',
    margin: '5px 2px',
    width: '23%',
    height: '38px',
    borderRadius: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#31374c',
    backgroundColor: '#ffffff',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#eaeef2a4',
    },
  },
  active: {
    background: '#eaeef2',
  },
  yearFilterIcon: {
    marginTop: '-5px',
  },
  calendarIcon: {
    width: '0.7em !important',
    float: 'left',
    color: '#4184E9',
  },
  arrowDownIcon: {
    float: 'right',
  },
});
