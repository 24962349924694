import { makeStyles } from '@mui/styles';

export default makeStyles({
  modalContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '24',
    p: '4',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: '#F2F8FF',
    padding: '8px',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      background: '#a6b7cb',
    },
  },
});
