import React from 'react';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { ICheckboxControlList } from './CheckboxControlList.interface';
import useStyles from './CheckboxControlList.styles';
import './CheckboxControlList.css';

/**
 * Component used to render checkbox lists
 *
 * @component
 */
const CheckboxControlList = (props: ICheckboxControlList) => {
  const classes = useStyles();
  const {
    checkboxArray,
    selectedItems,
    setSelectedItems,
    setSelectedItemsCallback,
    listIndex,
    useScroll,
  } = props;

  const parentDivClass = useScroll
    ? `${classes.checkboxContainer} ${classes.scroll}`
    : classes.checkboxContainer;

  return (
    <>
      {props.children}
      <div className={parentDivClass}>
        {checkboxArray.map(
          ({ label, enabled, description, prob_abandono }, checkboxIndex: number) => {
            let color = enabled ? '#31374c' : 'lightgray';

            // if (prob_abandono && enabled) {
            //   const quittingProbability = prob_abandono * 100;
            //   if (quittingProbability > 10 && quittingProbability <= 50) {
            //     color = '#F2C12A';
            //   } else if (quittingProbability > 50 && quittingProbability <= 75) {
            //     color = '#EE7E27';
            //   } else if (quittingProbability > 75) {
            //     color = '#F01B27';
            //   }
            // }
            
            return (
              <div key={`${label}-${checkboxIndex}`}>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  sx={{ color: color }}
                  disabled={!enabled}
                  label={label}
                  checked={
                    enabled && selectedItems && selectedItems.length > 0
                      ? selectedItems.includes(label)
                      : false
                  }
                  onChange={(event: any) => {
                    if (event.target.checked) {
                      setSelectedItems([...selectedItems, label]);
                    } else {
                      setSelectedItems(
                        selectedItems.filter((item: string) => item !== label),
                      );
                    }

                    if (
                      typeof setSelectedItemsCallback === 'function' &&
                      checkboxIndex === 0
                    ) {
                      setSelectedItemsCallback(event.target.checked, listIndex);
                    }
                  }}
                  control={<Checkbox />}
                />
                <Typography variant="caption" display="block">{description}</Typography>
              </div>
            );
          },
        )}
      </div>
    </>
  );
};

export default CheckboxControlList;
