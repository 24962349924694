import React from 'react';

import { Text, View, Font } from '@react-pdf/renderer';
import { styles } from './StudentScholarship.style';
import { parseDateToLocaleString } from 'utils/helper';

/**
 * this component renders all the student's personal data
 * @component
 */

const Footer = () => {
  return (
    <View style={styles.pageNumber} fixed>
      <Text style={styles.footerText}>
        Fecha: {parseDateToLocaleString(new Date())}
      </Text>
      <Text
        style={styles.footerText}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </View>
  );
};

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

export default Footer;
