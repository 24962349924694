import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import { IStudentScholarshipData } from '../StudentScholarship.interface';
import { styles } from '../StudentScholarship.style';

const SEMESTER = 'semester';

const CompulsorySubjectsTable = ({ data }: IStudentScholarshipData) => {
  const compulsorySubjectsGrades = data.grades.filter(
    (grade) => grade.concept && grade.type === 'B',
  );

  const compulsoryGradesBySemester = _.groupBy(
    compulsorySubjectsGrades,
    SEMESTER,
  );

     for (const semester in compulsoryGradesBySemester) {
       compulsoryGradesBySemester[semester].sort((a, b) => {
        const courseIdA = parseFloat(a.courseId);
        const courseIdB = parseFloat(b.courseId);
        return courseIdA - courseIdB;
       })
     }
  return (
    <>
      {Array(compulsoryGradesBySemester).length > 0
        ? Object.keys(compulsoryGradesBySemester).map((semester, index) => {
          return (
            <>
              <Text
                key={`excercise_title_${semester}_${index}`}
                style={styles.excersiceTitle}
              >
                  Semestre {semester}:
              </Text>
              <View
                key={`excercise_${semester}_${index}`}
                style={styles.tableContainer}
              >
                {/* Table header */}
                <View style={styles.container}>
                  <Text style={styles.code}>Código</Text>
                  <Text style={styles.descriptionHeader}>U.Curricular</Text>
                  <Text style={styles.columnHeader}>Créditos</Text>
                  <Text style={styles.columnHeader}>Duración</Text>
                  <Text style={styles.columnHeader}>Tipo</Text>
                  <Text style={styles.columnHeader}>Período académico</Text>
                  <Text style={styles.columnHeader}>Convocatoria</Text>
                  <Text style={styles.columnHeader}>Concepto</Text>
                  <Text style={styles.columnHeaderRight}>Calificación</Text>
                </View>

                {/* Table Info */}
                {Object.values(compulsoryGradesBySemester[semester]).map(
                  (item, index) => {
                    const renderGrade = () => {
                      if (
                        typeof item.grades === 'string' &&
                          !isNaN(parseInt(item.grades))
                      ) {
                        return (
                          <Text style={styles.columnRight}>
                            {Math.round(parseFloat(item.grades) * 100) / 100}
                          </Text>
                        );
                      } else if (item.type === 'L') {
                        return (
                          <Text style={styles.columnRight}>
                            {item.grades}
                          </Text>
                        );
                      } else {
                        return <Text style={styles.columnRight}> - </Text>;
                      }
                    };

                    return (
                      <View
                        style={styles.row}
                        key={`${item.courseId}_${item.call}_${item.excersice}_${index}`}
                      >
                        <Text style={styles.code}>{item.courseId}</Text>
                        <Text style={styles.courseName}>
                          {item.courseName}
                        </Text>
                        <Text style={styles.column}>
                          {item.practicalCredits > 0
                            ? item.practicalCredits
                            : item.theoricalCredits}
                        </Text>
                        <Text style={styles.column}>
                          {item.excersice === 'AN'
                            ? item.excersice.split('-')[1]
                            : item.excersice[item.excersice.length - 1]}
                        </Text>
                        <Text style={styles.column}>{item.type}</Text>
                        <Text style={styles.column}>{item.excersice}</Text>
                        <Text style={styles.column}>{item.call}</Text>
                        <Text style={styles.column}>{item.concept}</Text>
                        {renderGrade()}
                      </View>
                    );
                  },
                )}
              </View>
            </>
          );
        })
        : ''}
    </>
  );
};

export default CompulsorySubjectsTable;
