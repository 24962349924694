import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckDouble,
  faCheck,
  faTimes,
  faMinus,
  
} from '@fortawesome/free-solid-svg-icons';
import DownloadIcon from '@mui/icons-material/Download';
import { IHeaders } from 'components/list/ListHeaders/ListHeaders.interface';
import List from 'components/list/List';
import SearchInput from 'components/rootComponents/Standards/SearchInput/SearchInput';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import { IPostulaciones } from '../Postulaciones.interface';
import useLists from 'utils/customHooks/useLists';
import { generateAndDownloadCsvContentList } from 'utils/helper';

/**
 *
 * Postulaciones view to display different [[Chart]] and [[List]]
 *
 * @component
 */
const Postulaciones = (props: IPostulaciones) => {
  // Hooks
  const title = 'Postulaciones';
  const searchPlaceholder = 'Buscar postulaciones...';
  const {
    users,
    columns,
    isLoadingList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    search,
    setSearch,
    sortDirection,
    setSortDirection,
    totalPages,
  } = useLists('/v1.1/postulaciones/listado', props.filters);

  const headerCells: IHeaders[] = [
    {
      id: 'name',
      numeric: false,
      label: 'Nombre',
    },
  ];

  users[0]?.valores.forEach((valor: any, index: number) => {
    const isNumeric =
      valor.column === 'fecha_nacimiento' || valor.column === 'generacion';

    const formattedHeader: IHeaders = {
      id: valor.column,
      label: columns[index],
      numeric: isNumeric,
    };

    headerCells.push(formattedHeader);
  });

  const applicationsList = users.map((application: any) => {
    let formattedApplication = {
      name: application.nombre,
      link: application.__link,
    };

    for (let i = 0; i < application.valores.length; i++) {
      let column = application.valores[i].column;
      let value = application.valores[i].valor;

      formattedApplication = {
        ...formattedApplication,
        [column]: value,
      };
    }

    return formattedApplication;
  });


  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/postulaciones/graficas'}
        listUrl={'/v1.1/navegacion/postulaciones/listado'}
      />
      <Grid container item xs={12} md={12}>
        <Grid item xs={1}>
          <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
        </Grid>
        <Grid item xs={11}>
          Documentación sin subir. 
        </Grid>
        <Grid item xs={1}>
          <FontAwesomeIcon icon={faCheckDouble} style={{ color: 'green' }} />
        </Grid>
        <Grid item xs={11}>
          Documentación validada. 
        </Grid>
        <Grid item xs={1}>
          <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
        </Grid>
        <Grid item xs={11}>
          Documentación subida.
        </Grid>
        <Grid item xs={1}>
          <FontAwesomeIcon icon={faTimes} style={{ color: 'blue' }} />
        </Grid>
        <Grid item xs={11}>
          Documentación con observaciones.
        </Grid>
        <Grid item xs={1}>
          <FontAwesomeIcon icon={faMinus} style={{ color: 'blue' }} />
        </Grid>
        <Grid item xs={11}>
          No corresponde.
        </Grid>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <IconButton
          onClick={() =>
            generateAndDownloadCsvContentList(
              headerCells,
              applicationsList,
              'Postulaciones',
            )
          }
        >
          <DownloadIcon sx={{ color: '#007BFF' }} />
        </IconButton>
      </Grid>
      <Grid item xs={10} md={10} style={{ textAlign: 'right' }}>
        <SearchInput
          searchPlaceholder={searchPlaceholder}
          setSearchBar={setSearch}
        />
      </Grid>
      <Grid item xs={12}>
        <List
          search={search}
          headers={headerCells}
          rows={applicationsList}
          isLoading={isLoadingList}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPages={totalPages}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(Postulaciones);
