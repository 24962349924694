import React from 'react';
import { connect } from 'react-redux';

import { Grid, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import List from 'components/list/List';
import { IHeaders } from 'components/list/ListHeaders/ListHeaders.interface';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import SearchInput from 'components/rootComponents/Standards/SearchInput/SearchInput';
import useLists from 'utils/customHooks/useLists';
import { IGrupos } from './Grupos.interface';
import { generateAndDownloadCsvContentList } from 'utils/helper';

/**
 * /**
 * Grupos view to display different [[Chart]] and [[ArticleList]]
 *
 * @component
 */
const GruposListadoEstudiantes = (props: IGrupos) => {
  const locationSearch = props.location.search;
  const id = new URLSearchParams(locationSearch).get('id') || '';

  const title = 'Grupos';
  const searchPlaceholder = 'Buscar estudiante...';
  const {
    users,
    columns,
    isLoadingList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    search,
    setSearch,
    sortDirection,
    setSortDirection,
    totalPages,
  } = useLists('/v1.1/grupos/grupo/estudiantes', props.filters, 1000, id);

  const headerCells: IHeaders[] = [
    {
      id: 'name',
      numeric: false,
      label: 'Nombre',
    },
  ];
  users[0]?.valores.forEach((valor: any, index: number) => {
    // Exclude 'probabilidad_abandono' from the header cells
    if (valor.column !== 'probabilidad_abandono') {
      const isNumeric =
        valor.column === 'fecha_nacimiento' ||
        valor.column === 'generacion' ||
        valor.column === 'actividad_moodle' ||
        valor.column === 'actividad_foro';
  
      const formattedHeader: IHeaders = {
        id: valor.column,
        label: columns[index],
        numeric: isNumeric,
      };
  
      headerCells.push(formattedHeader);
    }
  });

  const studentsList = users.map((student: any) => {
    return {
      name: student.nombre,
      fecha_nacimiento: student.valores[0].valor,
      generacion: student.valores[1].valor,
      actividad_moodle: student.valores[2].valor,
      actividad_foro: Number(student.valores[3].valor),
      // probabilityOfQuitting: parseInt(
      //   student.valores[4].valor.replace('%', ''),
      // ),
      link: student.__link,
    };
  });
  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/grupos/graficas'}
        asignaturesUrl={'/v1.1/navegacion/grupos/listado'}
        listUrl={`/v1.1/navegacion/grupos/estudiantes?id=${id}`}
      />

      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <IconButton
          onClick={() =>
            generateAndDownloadCsvContentList(headerCells, studentsList, title)
          }
        >
          <DownloadIcon sx={{ color: '#007BFF' }} /> 
        </IconButton>
      </Grid>

      <Grid item xs={10} style={{ textAlign: 'right' }}>
        <SearchInput
          searchPlaceholder={searchPlaceholder}
          setSearchBar={setSearch}
        />
      </Grid>
      <Grid item xs={12}>
        <List
          search={search}
          headers={headerCells}
          rows={studentsList}
          isLoading={isLoadingList}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPages={totalPages}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(GruposListadoEstudiantes);
