import React from 'react';

import { Page, View, Text } from '@react-pdf/renderer';

import Header from 'components/userProfileInfo/studentScholarship/Header';
import Footer from 'components/userProfileInfo/studentScholarship/Footer';
import { IStudentScholarshipData } from 'components/userProfileInfo/studentScholarship/StudentScholarship.interface';
import { styles } from 'components/userProfileInfo/studentScholarship/StudentScholarship.style';
import CompulsorySubjectsTable from 'components/userProfileInfo/studentScholarship/listPage/CompulsorySubjectsTable';
import OptionalSubjectsTable from 'components/userProfileInfo/studentScholarship/listPage/OptionalSubjectsTable';
import { parseFullName, parseDateToLocaleString } from 'utils/helper';
import RemainingSubjectsTable from './RemainingSubjectsTable';

/**
 * this component renders the student's qualification list on a table into scholarship pdf.
 * @component
 */

const ListPage = ({ data }: IStudentScholarshipData) => {
  return (
    <>
      <Page size="A4" style={styles.listPage}>
        <Header />

        {/* User Info */}
        <View style={styles.studentHeaderWrapper} fixed>
          <View style={styles.studentHeaderView}>
            <Text style={styles.studentHeaderTextCapitalize}>
              Nombre Completo: {parseFullName(data.name, data.lastname)}
            </Text>
            <Text style={styles.studentHeaderText}>
              Documento de Identidad: {data.ci}
            </Text>
            <Text style={styles.studentHeaderText}>
              Cohorte: {data.admission_year}
            </Text>
          </View>
          <View style={styles.studentHeaderView}>
            <Text style={styles.studentHeaderText}>
              Plan de Estudios: {data.career}
            </Text>
            <Text style={styles.studentHeaderText}>Sede: {data.itr}</Text>
            <Text style={styles.studentHeaderText}>
              Fecha de Expedición: {parseDateToLocaleString(new Date())}
            </Text>
          </View>
        </View>

        <CompulsorySubjectsTable data={data} />

        <OptionalSubjectsTable data={data} />

        {!!data.remainingSubjects.length && (
          <RemainingSubjectsTable data={data} />
        )}

        {/* Table Footer */}
        <View>
          <View style={styles.container}>
            <View style={styles.row}>
              <Text style={styles.descriptionFooter}>
                Nota Promedio del estudiante:
              </Text>
              <Text style={styles.total}>{data.averageMark}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.acknowledgment}>
          La Universidad certifica que el estudiante con datos reseñados en la
          presente escolaridad, obtuvo las calificaciones que se detallan.
        </Text>
        <Footer />
      </Page>
      <Page size="A4" style={styles.listPage}>
        <Header />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ width: '48%' }}>
            <View style={styles.tableContainer}>
              {/* Table Header Tipologia */}
              <View style={styles.container}>
                <Text style={styles.descriptionHeaderExplain}>
                  Tip: indica la tipologia de la asignatura.
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> B </Text>
                <Text style={styles.description}> Obligatorias </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> O </Text>
                <Text style={styles.description}> Optativas </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> L </Text>
                <Text style={styles.description}> Libre Configuración </Text>
              </View>
              {/* Table Header Convocatoria */}
              <View style={styles.container}>
                <Text style={styles.descriptionHeaderExplain}>
                  Convocatoria:
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> CUR </Text>
                <Text style={styles.description}>
                  Calificacion obtenida del curso
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> 1PC </Text>
                <Text style={styles.description}> Primera convocatoria </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> 2PC </Text>
                <Text style={styles.description}> Segunda convocatoria </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> 3PC </Text>
                <Text style={styles.description}> Tercera convocatoria </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> EXL </Text>
                <Text style={styles.description}>
                  Convocatoria rendida en examen único.
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> TT </Text>
                <Text style={styles.description}>
                  {
                    'Tutoría: se califica con 1 si pierde y con 3 si aprueba. \n Se pondera con la calificacion obtenida en el EX'
                  }
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> EX </Text>
                <Text style={styles.description}>
                  Refleja la calificación obtenida en un examen. Pondera con la
                  nota de curso según Art 28 del RGE
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: '48%' }}>
            <View style={styles.tableContainer}>
              {/* Table Header escalas */}
              <View style={styles.container}>
                <Text style={styles.descriptionHeaderExplain}>Escala</Text>
                <Text style={styles.descriptionHeaderExplain}>Concepto</Text>
                <Text style={styles.descriptionHeaderExplain}>Descripcion</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.descriptionGrades}> 1-1.99 </Text>
                <Text style={styles.descriptionGrades}> DEF </Text>
                <Text style={styles.descriptionGrades}> Deficiente</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.descriptionGrades}> 2-2.99 </Text>
                <Text style={styles.descriptionGrades}> IN </Text>
                <Text style={styles.descriptionGrades}> Insuficiente </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.descriptionGrades}> 3-3.99 </Text>
                <Text style={styles.descriptionGrades}> SF </Text>
                <Text style={styles.descriptionGrades}> Suficiente </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.descriptionGrades}> 4-4.99 </Text>
                <Text style={styles.descriptionGrades}> MB </Text>
                <Text style={styles.descriptionGrades}> Muy Bueno </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.descriptionGrades}> 5 </Text>
                <Text style={styles.descriptionGrades}> EX </Text>
                <Text style={styles.descriptionGrades}> Excelente </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.descriptionGrades}> - </Text>
                <Text style={styles.descriptionGrades}> CC </Text>
                <Text style={styles.descriptionGrades}>
                  No presentado a examen
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.descriptionGrades}> - </Text>
                <Text style={styles.descriptionGrades}> RV </Text>
                <Text style={styles.descriptionGrades}>Reválida.</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.descriptionGrades}> 3 a 3.99 </Text>
                <Text style={styles.descriptionGrades}> SD </Text>
                <Text style={styles.descriptionGrades}>
                  Suficiente Condicional
                </Text>
              </View>
              <View style={styles.container}>
                <Text style={styles.descriptionHeaderExplain}>
                  Duración: indica la duración de la unidad académica
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> S </Text>
                <Text style={styles.description}> Semestral </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.code}> AN </Text>
                <Text style={styles.description}> Anual </Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.signTitle}>
          Se expide la presente escolaridad a pedido de la parte interesada, con
          la aprobación de la carrera y el sello de la institución, el día de la
          fecha.
        </Text>
        <View style={styles.signInput}>
          <Text style={{ flex: 1 }}>SECRETARÍA DE CARRERA</Text>
        </View>
        <Footer />
      </Page>
    </>
  );
};

export default ListPage;
