import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';

import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import Chart from 'components/charts/Charts';
import useCharts from 'utils/customHooks/useCharts';
import { IEstudiantes } from '../Estudiantes.interface';

/**
 *
 * Estudiantes view to display different [[Chart]] and [[List]]
 *
 * @component
 */
const EstudiantesGraficas = (props: IEstudiantes) => {
  //Hooks
  const { isLoadingCharts, charts } = useCharts(
    window.location.pathname,
    props.filters,
  );

  const title = 'Estudiantes';
  
  //Eliminar cuando se use probabilidad de abandono
  const filteredCharts = charts.filter(chart => chart.name !== 'Probabilidad de abandono');
 
  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: 'center', marginTop: '0px' }}
    >
      <ViewHeader
        title={title}
        chartUrl={'/v1.1/navegacion/estudiantes/graficas'}
        listUrl={'/v1.1/navegacion/estudiantes/listado'}
      />
      
      {isLoadingCharts && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      )}

      {!isLoadingCharts &&
        filteredCharts.map((chart, index) => {
          return (
            <Grid item key={index} xs={12}>
              <Chart {...chart} />
            </Grid>
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(EstudiantesGraficas);
