import { makeStyles } from '@mui/styles';

export default makeStyles({
  chartGridBox: {
    background: '#FFFFFF',
    border: '0.5px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25) !important',
    borderRadius: '12px !important',
    padding: '1rem !important',
  },
  chartTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#31374c',
    padding: '0.4rem 0',
    float: 'left',
  },
  infoIcon: {
    float: 'left',
  },
  seeMore: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textDecorationLine: 'none',
    cursor: 'pointer',
    color: '#007BFF',
  },
});