import { IUser } from 'services/user/user.interface';

/**
 * saves user data on redux
 */
export const saveUserLogIn = (user: IUser) => (dispatch: any) => {
  user.password = '';
  dispatch({ type: 'SAVE_USERLOGIN', payload: user });
};

/**
 * removes user from redux
 */
export const removeUserFromSession = () => (dispatch: any) => {
  const user: IUser = {
    username: '',
    loggedIn: false,
    remember: false,
  };
  dispatch({ type: 'DELETE_USERLOGIN', payload: user });
};

/**
 * if a token is detect on reload (f5) then we load it on redux to keep the user logged in
 */
export const readTokenFromSession = () => (dispatch: any) => {
  const sessionUser = sessionStorage.getItem('user_aa') || '';
  const userToken = sessionStorage.getItem('token') || '';
  const user: IUser = {
    loggedIn: userToken.length > 0,
    username: sessionUser,
    remember: false,
  };

  dispatch({ type: 'RELOAD_USERLOGIN', payload: user });
};

/**
 * update widths to show or hide filters
 */
export const updateFiltersVisibility =
  (visibility: boolean) => (dispatch: any) => {
    dispatch({ type: 'UPDATEFILTERS_USERLOGIN', payload: visibility });
  };
