import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton, CircularProgress, Typography, Stack, Modal } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { IHeaders } from 'components/list/ListHeaders/ListHeaders.interface';
import List from 'components/list/List';
import SearchInput from 'components/rootComponents/Standards/SearchInput/SearchInput';
import ViewHeader from 'components/rootComponents/Standards/ViewHeader/ViewHeader';
import { IPostulaciones } from '../Postulaciones.interface';
import useLists from 'utils/customHooks/useLists';
import { generateAndDownloadCsvContentList } from 'utils/helper';
import { Chip } from '@mui/material';
import Button from '@mui/material/Button';

const PostulacionesM = (props: IPostulaciones) => {
  const title = 'UC Aprobadas';
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar la apertura del modal
  const [asignaturasDiferenciaData, setAsignaturasDiferenciaData] = useState<string[]>([]); // Estado para almacenar las asignaturas pendientes

  const {
    users,
    columns,
    isLoadingList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    sortDirection,
    setSortDirection,
    totalPages,
  } = useLists('/v1.1/postulacionesm/listado', props.filters);

  const headerCells: IHeaders[] = [
    {
      id: 'name',
      numeric: false,
      label: 'Nombre',
    },
  ];

  users[0]?.valores.forEach((valor: any, index: number) => {
    const isNumeric =
      valor.column === 'fecha_nacimiento' || valor.column === 'generacion';

    const formattedHeader: IHeaders = {
      id: valor.column,
      label: columns[index],
      numeric: isNumeric,
    };

    headerCells.push(formattedHeader);
  });

  let filteredApplicationsList = users.map((application: any) => {
    let formattedApplication = {
      name: application.nombre, // Eliminar el enlace del nombre
    };
    for (let i = 0; i < application.valores.length; i++) {
      let column = application.valores[i].column;
      let value = application.valores[i].valor;

      if (column === 'asignaturas') {
        if (value) {
          value = value.split(',').map((asignatura: string, index: number) => (
            <Chip
              key={index} // Utiliza index como clave para evitar advertencias de clave única
              label={asignatura}
              style={{
                backgroundColor: '#D5E8C2',
                color: 'black',
                marginRight: '5px', // Espacio entre chips
                marginBottom: '5px', // Espaciado vertical
                fontFamily: 'Roboto', // Tipo de letra Roboto
                fontSize: '13px', // Tamaño de fuente 13px
              }}
            />
          ));
        } else {
          value = "-";
        }
      }

      if (column === 'porcentajeDiferencia') {
        const percentageValue = parseFloat(value);
        let progressValue = percentageValue; // Porcentaje de progreso normal
    
        // Si el porcentaje es 0, mostrar el círculo completo en rojo
        if (percentageValue === 0) {
            progressValue = 100;
        }
    
        const progressColor = percentageValue === 0 ? '#FDBFBF' : '#D5E8C2'; // Color rojo si es 0%, verde en otro caso
    
        formattedApplication = {
            ...formattedApplication,
            [column]: (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <div style={{ position: 'relative' }}>
                        <CircularProgress
                            size={48}
                            variant="determinate"
                            value={progressValue}
                            sx={{
                                color: progressColor,
                                '& .MuiCircularProgress-circle': {
                                    strokeLinecap: 'round', // Estilo de línea redondeada
                                },
                            }}
                        />
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.primary"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '0.8rem',
                            }}
                        >
                            {`${Math.round(percentageValue)}%`}
                        </Typography>
                    </div>
                </Stack>
            ),
        };
    } else {
        formattedApplication = {
          ...formattedApplication,
          [column]: value,
        };
      }

      // Agregar botón "Pendientes" para la columna "asignaturasDiferencia"
      if (column === 'asignaturasDiferencia') {
        formattedApplication = {
          ...formattedApplication,
          [column]: (
            <Button
              onClick={() => handlePendientesClick(value)}
              variant="contained"
              style={{
                color: '#FFFFFF', 
                fontSize: '14px', 
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif', 
                backgroundColor: '#007BFF', 
                padding: '12px 16px', 
                textTransform: 'none', 
                borderRadius: '15px',
              }}
            >
              Pendientes
            </Button>
          ),
        };
      }
    }

    return formattedApplication;
  });

  // Función para manejar el clic del botón "Pendientes"
  const handlePendientesClick = (asignaturasPendientes: string[]) => {
    setAsignaturasDiferenciaData(asignaturasPendientes);
    setModalOpen(true);
  };

  // Filtrar la lista de aplicaciones en función del término de búsqueda
  if (searchTerm.trim() !== '') {
    filteredApplicationsList = filteredApplicationsList.filter(application => {
      let found = false;
      for (const [column, value] of Object.entries(application)) {
        if (typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())) {
          found = true;
          break;
        } else if (Array.isArray(value)) {
          // Si el valor es un array, busca en cada elemento del array
          for (const item of value) {
            if (typeof item === 'string' && item.toLowerCase().includes(searchTerm.toLowerCase())) {
              found = true;
              break;
            }
          }
        }
      }
      return found;
    });
  }

  return (
    <Grid container spacing={2} style={{ alignItems: 'center', marginTop: '0px' }}>
      <ViewHeader title={title} />
      <Grid container item xs={12} md={12}></Grid>
      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <IconButton
          onClick={() =>
            generateAndDownloadCsvContentList(
              headerCells,
              filteredApplicationsList,
              'UC Aprobadas ',
            )
          }
        >
          <DownloadIcon />
        </IconButton>
      </Grid>
      <Grid item xs={10} md={10} style={{ textAlign: 'right' }}>
        <SearchInput
          searchPlaceholder="Buscar..."
          setSearchBar={setSearchTerm}
        />
      </Grid>
      <Grid item xs={12}>
        <List
          search={searchTerm} // Pasar el valor de searchTerm como el término de búsqueda
          headers={headerCells}
          rows={filteredApplicationsList}
          isLoading={isLoadingList}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPages={totalPages}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Grid>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '90%', // Ajusta el ancho máximo para asegurarte de que quepa en la pantalla
          maxHeight: '90%', // Ajusta la altura máxima para asegurarte de que quepa en la pantalla
          overflow: 'auto', // Agrega desplazamiento si el contenido es demasiado grande para el modal
          backgroundColor: '#FFFFFF',
          border: '2px solid #00AEEF',
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          padding: 16,
        }}>
          <h2 id="modal-modal-title">UC Pendientes</h2>
          <div id="modal-modal-description">
            {asignaturasDiferenciaData.map((materia, index) => (
              <Chip
                key={index}
                label={materia}
                style={{
                  margin: '4px',
                  backgroundColor: '#FDBFBF',
                  color: 'black',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontSize: '12px',
                }}
              />
            ))}
          </div>
        </div>
      </Modal>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(PostulacionesM);
