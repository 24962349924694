import { useEffect, useState } from 'react';
import { IOrder } from 'components/list/List.interface';

import { IFilter } from 'services/info/info.interface';
import ListService from 'services/list/list.service';

interface IListResponse {
  nombre: string;
  __link: string;
  valores: IValue[];
}

interface IValue {
  column: string;
  tipo: string;
  valor: string;
}

/**
 * This hook will show a [[List]] component with users retrieved from the API
 * @param url Url to retrieve the list of [[Chart]] per view
 * @param filters Filters stored in Redux used as http request body
 * @returns List of charts to be rendered on each view
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useLists = (url: string, filters: IFilter[], pageSize = 20, id = '') => {
  const abortController = new AbortController();
  const [isLoadingList, setIsLoadingList] = useState<boolean>(true);
  // data
  const [users, setUsers] = useState<IListResponse[]>([]);
  const [columns, setColumns] = useState<string[]>(['']);
  // pagination
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(pageSize);
  const [totalPages, setTotalPages] = useState<number>(0);
  // order & filters
  const [search, setSearch] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<IOrder>(IOrder.ASC);

  const getList = async () => {
    try {
      if (filters?.length === undefined || !filters || isLoadingList) {
        return;
      }

      setIsLoadingList(true);
      const result = await ListService.getListData(
        url,
        page,
        rowsPerPage,
        id,
        sortColumn,
        sortDirection,
        debouncedSearchTerm,
        filters,
        abortController.signal,
      );

      setColumns(result.columns);
      setTotalPages(result.totalPages);
      setUsers(result.users);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingList(false);
    }
  };

  useEffect(() => {
    getList();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    page,
    rowsPerPage,
    sortColumn,
    sortDirection,
    debouncedSearchTerm,
  ]);

  // Update debouncedSearchTerm after user stops typing for 500ms
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPage(1);
    }, 2000);

    // Clean up function
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  return {
    users,
    columns,
    isLoadingList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    search,
    setSearch,
    sortDirection,
    setSortDirection,
    totalPages,
  };
};

export default useLists;
